import styled from 'styled-components';

export const CustomSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333;
  z-index: 1;
  border: none;
  height: auto;
  top: 1px;
  box-sizing: border-box;
  background-color: rgb(255,255,255);
  padding: 10px;
  outline: none;
  border-radius: 0;
  border: 1px solid #ccc;
  border-bottom: 3px solid #ccc;
  color: #444;
  text-decoration: none;
  transition: all 0.25s ease;

  &:disabled {
    background-color: #ebebe4;
  }

  &::-ms-expand {
    display: none;
  }
`;

export const DropDownTitle = styled.div`
  color: #444;
  position: relative;

  ${props => props.disabled && 'background: #ebebe4;'}

  &:before {
    content: '|';
    position: absolute;
    right: 25px;
    color: #666;
    top: 9px;
  }

  &:after {
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48px' height='48px'%3E%3Cpath fill='%23f96302' d='M14.83,16.42L24,25.59l9.17-9.17L36,19.25l-12,12l-12-12L14.83,16.42z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    top: 0;
    right: 4px;
    background-size: 20px;
    background-position: 0;
    content: '';
    height: 40px;
    width: 0;
    padding: 0 0 0 20px;
    position: absolute;
    pointer-events: none;
    transition: all 0.25s ease;
  }
`;
