import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import findIndex from 'lodash/findIndex';
import flow from 'lodash/flow';
import find from 'lodash/find';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import { shouldHideOption } from '@gcc/configurator';
import ChoiceBoxSelect from '../../../components/ChoiceBoxSelect';
import { ChoiceImage } from '../../../components/ChoiceBox/styles';
import withIsMobile from '../../../components/withIsMobile';
import WarningMessage from '../../../components/WarningMessage';
import { OptionErrorMessages } from '../../../components/ErrorMessage';
import Carousel from '../../../components/Carousel';
import { Context as CollapseContext } from '../../../components/collapsibles';
import ConfiguratorTemplate from '../ConfiguratorTemplate';
import { Option, HeaderRow, InlineChoiceSectionHeader } from './styles';


const FixedSizeChoiceImageWrapper = styled.div`
  ${ChoiceImage} img {
    width: 98px;
    height: 94px;
  }
`;

class DefaultTemplate extends React.Component {
  getDefinition = () => this.props.attributes[camelCase(this.props.option.shortLabel)];

  getSelectedChoice() {
    const { choices } = this.props;
    return find(choices, 'isSelected');
  }

  getHrefForChoice = (choiceId) => {
    const { configLinks } = this.props;
    return configLinks[choiceId];
  };

  optionChange = (choice) => {
    const { id, shortLabel: choiceValue } = choice;
    this.props
      .onChoiceSelected(this.props.option.id, id, choiceValue)
      .then(() => this.props.onChoiceSelectedSuccess());
  };

  render() {
    const selectedChoice = this.getSelectedChoice();
    const { option } = this.props;
    const { choices } = this.props;
    const choiceInternalName = this.props.choices && this.props.choices.length > 0 &&
    this.props.choices[0].internalName;
    const sizedImage = (choice) => `${choice.mediaUrl}?fmt=jpeg&fit=constrain,1&hei=100`;
    const shouldHide = shouldHideOption(this.props.option, this.props.options, this.props.productChoices);
    const noOnlineChoices = filter(choices, choice => !includes(choice.tags, 'hide-online-pipfig'));
    return !shouldHide && (this.props.choices.length > 1 ||
      (this.props.choices.length === 1 &&
      (
        ((choiceInternalName.length >= 3) &&
        ((choiceInternalName.substring(0, 3)) !== 'No_'))
         || (choiceInternalName !== 'No'))
      )) &&
    (
      <CollapseContext.Provider fullCount={this.props.choices.length}>
        <Option>
          <HeaderRow>
            <InlineChoiceSectionHeader
              label={option.shortLabel}
              value={selectedChoice && selectedChoice.value}
              definitionMarkdown={this.getDefinition()}
            />
          </HeaderRow>
          <OptionErrorMessages option={option} />
          <FixedSizeChoiceImageWrapper>
            <CollapseContext.Consumer>
              {() => {
                // TODO this code basically does the following...
                //   if (mobile)           { return this.mobileRender(context);      }
                //   else if (collapsible) { return this.collapsibleRender(context); }
                //   else                  { return this.standardRender(context);    }
                // ...so make it look like that
                
                const selectedChoiceIndex = findIndex(choices, 'isSelected') || 0;


                const { isRequired, isIndexable } = option;

                const select = (
                  <ChoiceBoxSelect
                    options={noOnlineChoices}
                    value={choices[selectedChoiceIndex]}
                    keyBy={choice => choice.id}
                    labelBy={choice => choice.shortLabel}
                    imageBy={choice => sizedImage(choice)}
                    onChange={this.optionChange}
                    hrefBy={choice => this.getHrefForChoice(choice.id)}
                    indexable={isIndexable && isRequired}
                  />
                );

                return this.props.isMobile
                  ? <Carousel>{select}</Carousel>
                  : select;
              }}
            </CollapseContext.Consumer>
          </FixedSizeChoiceImageWrapper>
          <WarningMessage option={option} />
        </Option>
      </CollapseContext.Provider>
    );
  }
}

DefaultTemplate.propTypes = {
  choices: PropTypes.array.isRequired,
  option: PropTypes.shape({
    id: PropTypes.number.isRequired,
    shortLabel: PropTypes.string.isRequired,
    messages: PropTypes.array,
    isRequired: PropTypes.bool.isRequired,
    isIndexable: PropTypes.bool.isRequired,
  }).isRequired,
  options: PropTypes.array.isRequired,
  productChoices: PropTypes.array.isRequired,
  attributes: PropTypes.object.isRequired, // attributes is treated like a dictionary
  onChoiceSelected: PropTypes.func.isRequired,
  onChoiceSelectedSuccess: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  configLinks: PropTypes.object.isRequired,
};

export default flow(
  // include `isMobile` prop
  withIsMobile,
  // connect to redux
  ConfiguratorTemplate,
)(DefaultTemplate);
