import { takeLatest, put, select, call } from "redux-saga/effects";
import merge from 'lodash/merge';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import omit from 'lodash/fp/omit';
import compact from 'lodash/compact';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import _first from 'lodash/first';
import _some from 'lodash/some';
import _includes from 'lodash/includes';
import _replace from 'lodash/replace';
import filter from 'lodash/filter';
import galleryApi from '../../api/galleryApi';
import config from '../../config';
import { UPDATE_PRODUCTINFO, CLEAR_PRICING,  } from "../../actions/actionTypes";
import safeSaga from "../safeSaga";
import addConfigurationToCartV2 from '../../actions/cartActions/addConfigurationToCartV2';
import { skuMapSelector } from '../../selectors/skuMap/skuMapSelector';
import {
  configuratorSelector,
  productDetailsSelector,
  productSelector,
  productInfoSelector,
  cartProductCartSelector,
} from "../../selectors";
import gallerySearchTokenGenerator from "../../utils/gallerySearchTokenGenerator";
import { updateCartProductGallery } from "../../actions/getGallery";

function* isFullyVisualized(primaryImage, skuMap) {
  // 1. primary image named based on choiceIds
  // 2. primary image named based on anchor sku and its not empty
  // 3. primary image named based on subskus
  return (!isEmpty(skuMap.hash) && primaryImage === _replace(skuMap.hash, /-/g, '_'))
    || (_includes(primaryImage, skuMap.sku) && !isEmpty(skuMap.sku))
    || _some(skuMap.subSkus, subSku => (_includes(primaryImage, subSku.sku)));
}

function* generateGallerySearchToken(skuMap, product, choices, productId, options, visualizedSiteProductOptions) {
  const token = gallerySearchTokenGenerator(
    skuMap,
    product,
    choices,
    productId,
    options,
    visualizedSiteProductOptions
  );

  return token;
}

function* getItemIds(productInfo) {
  const attachmentItemIds = flow(
    omit(['DefaultProduct', 'primary']),
    map(p => p.itemId),
  )(productInfo) || [];

  const itemIds = compact([get(productInfo, 'primary.itemId'), ...attachmentItemIds.slice(0, 4)]);
  return itemIds;
}

function* getDynamicGalleryImage(galleryAssets) {
  const productInfo = yield select(productInfoSelector);
  const itemIds = yield call(getItemIds, productInfo);

  if (!isEmpty(itemIds)) {
    try {
      galleryAssets.unshift({
        id: 'dynamicGalleryImage',
        sizes: ["100", "145", "600", "1000"],
        type: 'IMAGE',
        url: `${config.dynamicGalleryApiUrl}?${itemIds.map(x => `items=${encodeURIComponent(x)}`).join('&')}&hei=<SIZE>`,
        primaryLink: `${config.dynamicGalleryApiUrl}?${itemIds.map(x => `items=${encodeURIComponent(x)}`).join('&')}&hei=<SIZE>`,
        thumbnail: `${config.dynamicGalleryApiUrl}?${itemIds.map(x => `items=${encodeURIComponent(x)}`).join('&')}&hei=<SIZE>`,
        source: "THD_DYNAMIC_GALLERY",
      });
    } catch (err) {
      console.log(err);
    }
  }

  return galleryAssets;
}

function* getLayeredvisualizedGalleryImage(galleryAssets, productId) {
  const { options, choices } = yield select(configuratorSelector);
  const filteredChoices = filter(
    choices,
    (choice) => options[choice.optionId].isActive
      && choice.isSelected
  );
  const selectionsString =  Object.entries(filteredChoices)
  .map(([key, value]) => `${encodeURIComponent(value.optionId)}=${encodeURIComponent(value.id)}`)
  .join('&');

  try {
    galleryAssets.unshift({
      id: 'layeredVisualizerImage',
      sizes: ["100", "145", "600", "1000"],
      type: 'IMAGE',
      url: `${config.layeredVisualizationImageUrl}/${productId}?${selectionsString}&size=<SIZE>&default=true`,
      primaryLink: `${config.layeredVisualizationImageUrl}/${productId}?${selectionsString}&size=<SIZE>&default=true`,
      thumbnail: `${config.layeredVisualizationImageUrl}/${productId}?${selectionsString}&size=<SIZE>&default=true`,
      source: "GCC_LAYERED_VISUALIZATION",
    });
  } catch (err) {
    console.log(err);
  }
  return galleryAssets;
}

function* getGallery() {
  let galleryAssetData;

  try {
    const currProduct = yield select(productSelector);
    const skuMap = yield select(skuMapSelector);
    const { choices, options } = yield select(configuratorSelector);
    const {
      visualizedSiteProductOptions,
      productId,
      attributes: {
        dynamicGallery,
      },
      visualizationMode,
    } = yield select(productDetailsSelector);

    if (typeof (productId) === 'undefined') return;

    const searchToken = yield call(generateGallerySearchToken, skuMap, currProduct, choices, productId, options, visualizedSiteProductOptions);

    const folder = currProduct?.details?.attributes?.scene7Folder;

    const isLayeredVisualization = visualizationMode === 'Layered';

    if (!folder) return;

    const gallery = yield call(galleryApi, folder, searchToken, isLayeredVisualization);
    const galleryData = gallery.assets.map(x => {
      const id = x.s3ObjectName;
      const url = x.primaryLink.replace("hei=1000", "hei=<SIZE>") || x.primaryLink.replace("hei=100", "hei=<SIZE>");
      const videoStill = x.mediaType === 'VIDEO' ? x.mainImage : null;
      const sizes = ["100", "145", "600", "1000"];

      return {
        id,
        sizes,
        type: x.mediaType,
        url,
        source: "CONFIGURABLE_S3",
        videoStill,
        primaryLink: x.primaryLink,
        thumbnail: x.thumbnail,
      };
    });

    galleryAssetData = galleryData;
    const images = filter(galleryAssetData, i => i.type === 'IMAGE');
    const video = filter(galleryAssetData, i => i.type === 'VIDEO');
    const media = {
      image: {
        url: images?.[0]?.primaryLink
      },
      images,
      video
    };

    if (dynamicGallery === 'Yes') {
      const dynamicGalleryAssets = yield call(getDynamicGalleryImage, galleryAssetData);
      yield put(updateCartProductGallery(media, dynamicGalleryAssets));
      return;
    }

    if (isLayeredVisualization) {
      const layeredVisualizationGalleryAssets = yield call(getLayeredvisualizedGalleryImage, galleryAssetData, productId);
      yield put(updateCartProductGallery(media, layeredVisualizationGalleryAssets));
      return;
    }

    // early exit for performance
    const earlyHasFullVisualization = searchToken === galleryAssetData[0].id || searchToken === 'no-configuration-value';
    if (earlyHasFullVisualization) {
      yield put(updateCartProductGallery(media, galleryAssetData));
      return;
    }

    const primaryImage = searchToken === galleryAssetData[0].id
        ? galleryAssetData[0].id
        : searchToken;
    const hasFullVisualization = yield call(isFullyVisualized, primaryImage, skuMap);

    if (!hasFullVisualization) {
      const dynamicGalleryAssets = yield call(getDynamicGalleryImage, galleryAssetData);
      yield put(updateCartProductGallery(media, dynamicGalleryAssets));
      return;
    }
    yield put(updateCartProductGallery(media, galleryAssetData));
    return;
  } catch (err) {
    console.log(err);
  }
  finally {
    const cart = yield select(cartProductCartSelector);
    if ((cart && cart?.length === 0) || !cart?.[0]?.itemId){
      yield put(addConfigurationToCartV2());
    }
  }
}
export default function* gallerySaga() {
  yield takeLatest([UPDATE_PRODUCTINFO, CLEAR_PRICING], safeSaga(getGallery));
}
