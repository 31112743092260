import { takeLatest, select } from "redux-saga/effects";
import {
  UPDATE_SO_DELIVERY_TIME,
  UPDATE_SKUMAP,
} from "../../actions/actionTypes";
import safeSaga from "../safeSaga";
import {
  productionLeadTimeSelector,
  skuMapSelector,
} from "../../selectors";

function* setLeadTime(action) {
  const leadTime = yield select(productionLeadTimeSelector);
  const {isSpecialOrder} = yield select(skuMapSelector);
  if (isSpecialOrder) {
    LIFE_CYCLE_EVENT_BUS.trigger("configurator.set_lead_time", leadTime);
  } else {
    LIFE_CYCLE_EVENT_BUS.trigger("configurator.set_lead_time", undefined);
  }
}

export default function* harmonySaga() {
  yield takeLatest([UPDATE_SO_DELIVERY_TIME, UPDATE_SKUMAP], safeSaga(setLeadTime));
}
