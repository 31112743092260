import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import _merge from 'lodash/merge';

export const mergeFulfillmentOptions = (objVal, srcVal) => {
  const objFulfillmentOptions = objVal?.fulfillment?.fulfillmentOptions;
  const srcFulfillmentOptions = srcVal?.fulfillment?.fulfillmentOptions;
  if (!_isEmpty(objFulfillmentOptions) && !_isEmpty(srcFulfillmentOptions)) {
    if (_size(objFulfillmentOptions) !== _size(srcFulfillmentOptions)) {
      // IMPORTANT: only take obj ignore src when src has existing fulfillment option still
      // pickup + delivery => only pickup (issue is it holds on to prev delivery option)
      return objVal;
    }

    return _merge({}, objVal, srcVal);
  }

  return _merge({}, objVal, srcVal);
}