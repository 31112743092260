import styled, { css } from 'styled-components';
import * as col from '../../utils/colors';

export const ChoiceDescription = styled.div`
  display: table-caption;
  justify-content: center;
  line-height: 16px;
  caption-side: bottom;
  height:40px;

  @media (max-width: 767px) {
    font-size: 1.25rem;
    padding: 0 1px;
  }
`;
export const ChoiceDescriptionLabel = styled.div`
  position: relative;
  top: 50%;
  text-align: left;
  left: 2%;
  transform: translateY(-59%);
`;
export const ChoiceBorder = styled.div`
  border: 2px solid ${col.celesteApprox};
  border-radius: 3px;
  margin: 0 10px 10px 0;
  padding: 4px;
  color: ${col.darkText} !important;
  a {
      color: ${col.darkText} !important;
    }

  @media (max-width: 767px) {
    font-size: 1.25rem;
    line-height: 16px;
    padding: 0 1px;
  }

  ${props => props.selected && css`
    padding: 3px;
    border: 2px solid ${col.thdOrange};

    @media (max-width: 767px) {
      padding: 0 1px;
    }

    ${ChoiceDescription} {
      font-weight: 600;
    }
  `}
`;

export const BoxChoice = styled.div`
  display: table;
  padding: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;

  &:last-of-type {
    margin-right: 0px;
  }
`;

export const ChoiceImage = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 5px 0px;

  img {
    margin: auto;
  }

  svg {
    margin: 0 auto;

    .selectable {
      fill: ${col.blazeOrangeApprox};
    }
  }
`;
