import axios from 'axios';
import config from '../config';
import toCamelCase from '../utils/camelCase';

export const removeLineItem = (lineItemId) => {
  const url = `${config.thdCartApiUrl}/delete/${lineItemId}`;
  const data = {};

  return axios({
    method: 'post',
    url,
    data,
    responseType: 'json',
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'X-Client-Group': 'blinds.com',
    },
  })
    .then((response) => toCamelCase(response.data))
    .catch((error) => error);
};
