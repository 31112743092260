/* eslint-disable no-multi-spaces */
// eslint-disable-next-line import/no-unresolved
import { ExperienceContext } from '@thd-nucleus/experience-context';
import updateThdStoreInfo from '../actions/updateThdStoreInfo';
import store from '../store/store';

const localizer = typeof window !== 'undefined' ? window?.THD_LOCALIZER_AUTO_INIT?.Localizer : null;

export const localize = () => {
// doesn't need user permission
// (falls back to Cumberland if user lives somewhere where they have rights)
if (localizer) {

  const initialPoll = setInterval(() => {
    if (store.getState().thdStoreInfo.storeNumber) {
      clearInterval(initialPoll);
      return;
    }
  }, 1000);

  const toReduxFormat = ({ name, storeId, ...storeInfo }) => ({
    ...storeInfo,
    // match legacy names (used throughout app)
    storeName: name,
    storeNumber: storeId,
  });

  function updateGeolocationStoreInfo(localizer) {
    const storeInfo = localizer?.getLocalizedStore();
    const deliveryZip = window?.__EXPERIENCE_CONTEXT__?.deliveryZip || storeInfo.zipcode;

    if (storeInfo) {
      store.dispatch(updateThdStoreInfo(toReduxFormat({ ...storeInfo, deliveryZip: deliveryZip })));
    }
  }

  // try geolocation (localizer)
  // only works when user grants permission
    updateGeolocationStoreInfo(localizer);
    localizer?.on(localizer?.event?.LOCALIZED, updateGeolocationStoreInfo(localizer));
    localizer?.on(localizer?.event?.LOCALIZED_STORE_CHANGED, updateGeolocationStoreInfo(localizer));
  }
}
