import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Slider = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  padding-left: 1px;
  padding-right: 1px;

  * {
    flex-shrink: 0 ! important;
  }
  > * {
    overflow: visible !important;
  }
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 75px;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
  pointer-events: none;
`;

const GradientLeft = styled(Gradient)`
  left: 0;
  z-index: 90;
`;

const GradientRight = styled(Gradient)`
  right: 0;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  z-index: 90;
`;

const ScrollArrow = styled.i`
  border: solid rgb(249, 99, 2);
  border-width: 0 3px 3px 0;
  padding: 5px 5px 5px 5px;
  display: inline-block;
`;

const LeftScrollArrow = styled(ScrollArrow)`
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
`;

const RightScrollArrow = styled(ScrollArrow)`
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

const ArrowWrapper = styled.div`
  align-self: center;
`;

const ArrowBox = styled.div`
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  width: 1em;
  height: 2em;;
  font-size: 1.5em;
  background: white;
  border: 1px lightgrey solid;
  cursor: pointer;
  display: none;

  &:hover {
    background-color: #eee;
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  @media (min-width: 360px) {
    ${props => props.oneItemPerSlide && css`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  `}
  }
`;

const LeftArrowBox = styled(ArrowBox)`
  left: .5em;
  padding-left: .25em;
  z-index: 100;
`;

const RightArrowBox = styled(ArrowBox)`
  right: .5em;
  padding-right: .25em;
  z-index: 100;
`;

export {
  Wrapper,
  Slider,
  LeftScrollArrow,
  RightScrollArrow,
  GradientLeft,
  GradientRight,
  LeftArrowBox,
  RightArrowBox,
  ArrowWrapper,
};
