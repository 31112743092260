import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty'
import { isHomeDelivery } from '../../utils/isHomeDelivery';
import { UPDATE_CART_PRODUCT } from '../actionTypes';
import choiceAndSkuAvailability from '../../actions/outOfStock/choiceAndSkuAvailability';
import { removeLineItem } from '../../api/removeLineItem';
import { cartProductSelector, lineItemGuidSelector } from '../../selectors';

const addGccConfigurationToCart = (configurationGuid, items, cartOptions) => (dispatch, getState) => {
  const {
    config: {
      partnerProductId,
      isCopy,
    },
    thdStoreInfo: {
      storeNumber: localStoreId,
      zipcode: zipCode,
    },
  } = getState();
  const product = cartProductSelector(getState());
  const lineItemGuid = lineItemGuidSelector(getState());

  const itemDetails = [
    cartOptions?.storeId && cartOptions?.fulfillmentMethod && !isHomeDelivery(cartOptions?.fulfillmentMethod) ? {
      itemId: partnerProductId.toString(),
      configurationId: configurationGuid,
      quantity: product?.configuration?.cart?.[0]?.quantity || 1,
      fulfillmentMethod: cartOptions.fulfillmentMethod,
      fulfillmentLocation: cartOptions.storeId,
    } : {
      itemId: partnerProductId.toString(),
      configurationId: configurationGuid,
      quantity: product?.configuration?.cart?.[0]?.quantity || 1,
      fulfillmentLocation: zipCode,
      fulfillmentMethod: cartOptions.fulfillmentMethod,
      storeId: localStoreId,
      zipCode,
    },
  ];

  delete product?.configuration?.cart;
  delete product?.configuration?.items;

  const configuration = {
    deliveryTime:  product?.configuration?.deliveryTime,
    cart: itemDetails,
    items: items
  };

  const parsedData = merge(
    {},
    { product },
    { product: { configuration } },
  );

  LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_data", { configuratorData: parsedData });

  if (!isEmpty(lineItemGuid) && !isCopy) {
    Promise.resolve(removeLineItem(lineItemGuid));
  }

  Promise.resolve(dispatch({
    type: UPDATE_CART_PRODUCT,
    data: parsedData,
  }))
  .then(() => Promise.resolve(dispatch(choiceAndSkuAvailability({ isStupidProduct: true, overrideStoreNumber: cartOptions.storeId }))))
  .then ((res) => {
    // If triggered from FSS (Fulfillment Store Selector) drawer
    // We need to do this so we don't endlessly pollute DOM with new iframes
    if (cartOptions?.triggeredFromStoreDrawer) {
      window.postMessage({
          action: 'event',
          type: 'cart-pss-atc-event',
          cartModel: parsedData.product.configuration.cart,
          cartOptions: {
            host: window?.location?.origin || 'https://www.homedepot.com',
            channel: cartOptions?.channel || 'desktop',
            skipMisship: true
          }
        });
    } else {
      // If triggered from Buybox Add To Cart button
      window.LIFE_CYCLE_EVENT_BUS.trigger('cart.add-to-cart', {
        cartReqParams: parsedData.product.configuration.cart,
        cartOptions,
      });
    }
  });
};

export default addGccConfigurationToCart;
