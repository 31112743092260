/* eslint-disable no-mixed-operators */
import differenceInDays from 'date-fns/difference_in_days';
import isWeekend from 'date-fns/is_weekend';
import addDays from 'date-fns/add_days';
import { parse, isValid } from 'date-fns';

// business day logic courtesy of:
// https://stackoverflow.com/questions/21297323/how-do-i-add-business-days-in-javascript

/* eslint-disable no-multi-spaces */
const holidays = {
  '11-4/4': true,  // Thanksgiving Day, fourth Thursday in November
  '12/24+5': true, // Christmas Day
  '12/25': true,   // Christmas Day
  '12/26+1': true, // Christmas Day
};
/* eslint-enable no-multi-spaces */

export function isHoliday(date) {
  const dateClone = new Date(date);

  const dayOfWeek = dateClone.getDay();
  const dayOfMonth = dateClone.getDate();
  const month = dateClone.getMonth() + 1;
  const monthDay = `${month}/${dayOfMonth}`;

  if (holidays[monthDay]) {
    return true;
  }

  const monthDayDay = `${monthDay}+${dayOfWeek}`;
  if (holidays[monthDayDay]) {
    return true;
  }

  const weekOfMonth = Math.floor((dayOfMonth - 1) / 7) + 1;
  const monthWeekDay = `${month}-${weekOfMonth}/${dayOfWeek}`;
  if (holidays[monthWeekDay]) {
    return true;
  }

  const lastDayOfMonth = new Date(dateClone);
  lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
  lastDayOfMonth.setDate(0);

  const negWeekOfMonth = Math.floor((lastDayOfMonth.getDate() - dayOfMonth - 1) / 7) + 1;
  const monthNegWeekDay = `${month}~${negWeekOfMonth}/${dayOfWeek}`;
  if (holidays[monthNegWeekDay]) {
    return true;
  }

  return false;
}

export function isBusinessDay(date) {
  return !(
    isWeekend(date)
    || isHoliday(date)
  );
}

export function addBusinessDays(date, businessDays) {
  let endDate = new Date(date);

  let remainingBusinessDays = businessDays;
  while (remainingBusinessDays >= 0) {
    endDate = addDays(endDate, 1);

    if (isBusinessDay(endDate)) {
      remainingBusinessDays -= 1;
    }
  }

  return endDate;
}

export function differenceInBusinessDays(date, businessDays) {
  const startDate = new Date(date);
  const endDate = addBusinessDays(startDate, businessDays);

  return differenceInDays(startDate, endDate);
}

export function convertToLocalDateTime(date) {
  const formattedDate = date.replace('T', ' ');
  const createdDate = new Date(`${formattedDate} UTC`);
  const options = {
    year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',
  };
  return createdDate.toLocaleDateString('en-US', options);
}

export function parseDateByFormatString(dateString, formatString) {
  if (!dateString || !formatString) {
    return null;
  }

  const parsedDate = parse(dateString, formatString, new Date());

  // Return the parsed date if it is valid, otherwise return null
  return isValid(parsedDate) ? parsedDate : null;
}