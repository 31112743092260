import { CUSTOM_PRICE_VSO } from '../../../utils/pipFiguratorType';

export function harmonyProductData(
  originalPrice,
  discount,
  percent,
  specialBuy,
  specialPrice,
  someSkusHaveZeroPrice,
  partnerProductId,
  pipfiguratorType
 ) {
  const isStartingAtPipfiguratorType = pipfiguratorType === CUSTOM_PRICE_VSO;
  return {
    configuratorPricing: {
      itemId: partnerProductId,
      identifiers: {
        itemId: partnerProductId
      },
      pricing: {
        alternate: {
          bulk: {
            value: null,
            thresholdQuantity: null,
          },
          unit: {
            unitsPerCase: null,
            caseUnitOfMeasure: null,
            unitsOriginalPrice: null,
            value: null,
          }
        },
        alternatePriceDisplay: false,
        mapAboveOriginalPrice: null,
        message: isStartingAtPipfiguratorType ? 'Starting at' : null,
        original: originalPrice,
        preferredPriceFlag: null,
        promotion: {
          dates: {
            start: null,
            end: null,
          },
          dollarOff: discount,
          percentageOff: percent,
          savingsCenter: specialBuy,
          savingsCenterPromos: null,
          specialBuySavings: null,
          specialBuyDollarOff: null,
          specialBuyPercentageOff: null,
          type: "DISCOUNT",
        },
        specialBuy: null,
        unitOfMeasure: "each",
        value: specialPrice,
        someSkusHaveZeroPrice,
      },
      info: {
        sskMax: null,
        sskMin: null
      },
      disableRangePricing: !isStartingAtPipfiguratorType,
      omitDetails: !isStartingAtPipfiguratorType,
      showStartingAt: isStartingAtPipfiguratorType
    }
  };
}
