import { useDataModel } from '@thd-nucleus/data-sources';
import { getMappedSkus } from '../sagas/pricingSaga/_utils';
import _first from 'lodash/first';

const useNucleusData = ({
  storeId, itemId, zipCode, skuMap, itemIdForDisplay, directData = null, quantity = 1
}) => {
  const productOptions = {
    skip: storeId === '8119' || !!directData || !itemId,
    ssr: false,
    variables: {
      itemId: String(itemId),
      quantity,
      storeId,
      zipCode,
    }
  };

  let productOptionsForNonSpecialOrders = null;
  if (!skuMap.isSpecialOrder) {
    const skuNumbers = getMappedSkus(skuMap);
    const itemId = _first(skuNumbers);
    if (itemId) {
      productOptionsForNonSpecialOrders = {
        skip: storeId === '8119' || !!directData,
        ssr: false,
        variables: {
          itemId: itemIdForDisplay > 0 ? String(itemIdForDisplay) : String(itemId),
          quantity,
          storeId,
          zipCode,
        }
      };
    }
  }

  // For products that have state => skuMap.isSpecialOrder = false, we have a different way of determining the itemIds
  // to send to graphql. But not all products work this way. We have some that are non-sku-map and isSpecialOrder = false.
  // For those, we should use the standard method of finding itemId. Unfortunately, there is no real way to know if something
  // is a non-sku-map and isSpecialOrder = false product. In this case, we try the isSpecialOrder = false method and it will
  // return no results. Then we try the standard method.
  const { data, loading, error } = useDataModel('clientOnlyProduct', productOptionsForNonSpecialOrders ?? productOptions);
  // const { data: data2, loading: loading2, error: error2 } = useDataModel('clientOnlyProduct', productOptions);

  LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_available_items", {
    items: [{
      itemId: productOptionsForNonSpecialOrders?.variables?.itemId ?? productOptions?.variables?.itemId,
      isAnchor: true,
    }]
  });

  return { data: data || { product: directData }, loading, error };
};

export default useNucleusData;
