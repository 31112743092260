import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { updateChoiceSuccess } from '@gcc/configurator';
import store from '../../store/store';
import { createConfigurationWithSkuPricing, createConfiguration } from '../../api/configuratorSettings';
import config from '../../config';
import {
  appendHeightAndWidthToSelections,
  updateHeightAndWidthToSelectionsObject,
} from '../../utils/getConfiguratorSettings';
import { cartProductMediaSelector } from '../../selectors';

function filterSelections(choice, options, choices) {
  var option = options[choice.optionId];

  if (choice.isSelected && option.isDependent) {
    var parentChoice = choices[option.parentChoiceId];

    if (parentChoice.isSelected && option.isActive && choice.isActive) {
      return filterSelections(parentChoice, options, choices);
    }

    return false;
  }

  return choice.isSelected && choice.isActive && option.isActive;
}

const getSelectedChoices = (options, choices) => {
  const configurator = store.getState().configurator;
  const { bypassSkuMap } = store.getState().product?.details?.attributes;
  if (configurator?.savedProductChoices?.[config.productId]?.[config.partnerProductId]
    && (config.useMSRP || (bypassSkuMap && bypassSkuMap === 'True'))) {
    return filter(
      choices,
      (choice) => filterSelections(choice, options, choices),
    );
  }

  const isSkuMapped = (option) => {

    return (
      //This can be moved to a seperate config and made dynamic
      includes(option.tags, 'include-in-config')
      || !includes(option.tags, 'notskumapped')
      || includes(option.tags, 'category-quantity')
      || includes(option.tags, 'pipfigurator:hidden')
      || option.internalName === 'Manufacturer_Part_Number'
      || option.internalName === 'Store_Sku'
    )
  };

  return filter(
    choices,
    (choice) => options[choice.optionId].isActive
      && choice.isSelected
      && isSkuMapped(options[choice.optionId]),
  );
};

const getSelections = (options, choices) => map(getSelectedChoices(options, choices), (choice) => {
  const option = options[choice.optionId];
  return {
    choiceId: choice.id,
    choiceValue: choice.shortLabel,
    choiceName: choice.internalName,
    optionId: choice.optionId,
    optionTags: option.tags,
    optionName: option.internalName,
  };
});

const buildHiddenSOOption = () => {
  const OPTION_INTERNAL_NAME_PART_NUMBER = 'Manufacturer_Part_Number';
  const CHOICE_INTERNAL_NAME_PART_NUMBER = 'Part_Number';
  const {
    configurator: {
      options,
      choices
    },
    skuMap: {
      sku: mpnSku
    }
  } = store.getState();

  // MPN OPTION
  const mpnOption = find(options, (option) => option.internalName === OPTION_INTERNAL_NAME_PART_NUMBER);
  const optionId = get(mpnOption, 'id', null);
  // MPN CHOICE + VALUE
  const mpnChoice =  optionId && find(choices, (choice) => choice.internalName === CHOICE_INTERNAL_NAME_PART_NUMBER);
  const choiceId = get(mpnChoice, 'id', null);
  const choiceValue = mpnSku || null;

  return { optionId, choiceId, choiceValue };
};

export const sendHiddenOption = () => {
  const { skuMap } = store.getState();
  const { bypassSkuMap } = store.getState().product?.details?.attributes;
  const { optionId, choiceId, choiceValue } = buildHiddenSOOption();
  if (config.useMSRP || (bypassSkuMap && bypassSkuMap === 'True' || skuMap?.isSpecialOrder)) {
  if (optionId && choiceId) {
    return store.dispatch(updateChoiceSuccess(optionId, choiceId, choiceValue, true));
  };
  }
}

export const createCartConfiguration = ({overrideStoreNumber}) => {
  const { bypassSkuMap } = store.getState().product?.details?.attributes;
  const configurator = store.getState().configurator;
  const product = store.getState().product;
  const config = store.getState().config;
  const media = cartProductMediaSelector(store.getState());
  const mainImageUrl = media?.images?.[0]?.primaryLink;
  const { skuMap } = store.getState();
  const { storeNumber } = store.getState().thdStoreInfo;
  const { options, choices } = configurator;
  const { productId, partnerProductId } = config;

  let data = getSelections(options, choices);
  let data2 = configurator?.savedProductChoices?.[productId]?.[partnerProductId];
  if (bypassSkuMap === 'True') {
    data = appendHeightAndWidthToSelections(data, options, choices);
    data2 = updateHeightAndWidthToSelectionsObject(data2, options, choices);
  }

  let selections;
  const arr = [];
  let soFlag = skuMap?.isSpecialOrder;
  const { sku } = skuMap;

  if (data2) {
    forEach(Object.keys(data2), (x) => {
      const obj = data.find((d) => {
        return d.optionId === Number(x);
      });

      if (obj?.optionName === "Manufacturer_Part_Number") {
        soFlag = true;
        obj.choiceValue = !isEmpty(skuMap.sku) ? skuMap.sku : null;
      } else if (obj?.choiceValue && data2?.[x]?.choiceValue){
        obj.choiceValue = data2[x].choiceValue;
      }

      if (obj) {
        arr.push(obj);
      }
    });
  } else {
    const buildSelectionsForConfiguration = map(data, (selection) => ({
      ...selection,
      choiceValue: choices?.[selection?.choiceId]?.value || selection?.choiceValue
    }))
    arr.push(...buildSelectionsForConfiguration);
  }
  if (!skuMap.configurationError) {
    selections = arr;
    let promise = () => Promise.resolve(createConfigurationWithSkuPricing(product.details.productId, data, overrideStoreNumber ?? storeNumber, mainImageUrl, skuMap?.sku, skuMap?.skuType));

    if ((soFlag && sku === "") || bypassSkuMap === 'True') {
      promise = () => Promise.resolve(createConfiguration(product.details.productId, selections, overrideStoreNumber ?? storeNumber, mainImageUrl));
    }

    return promise();
  }
};
