import React from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import _filter from 'lodash/filter';

import AlertDanger from '../alerts/AlertDanger';
import { StyledError } from './styles';

const getErrors = option => _filter(option && option.messages, m => m.messageType.toLowerCase() === 'error');

export const OptionErrorMessages = props =>
  getErrors(props.option).length > 0 && (
    <StyledError>
      {getErrors(props.option).map(({ message }) => (
        <AlertDanger key={message || _uniqueId()}>
          {message || 'Please select from an available option.'}
        </AlertDanger>
      ))}
    </StyledError>
  );

OptionErrorMessages.defaultProps = {
  option: null,
};

OptionErrorMessages.propTypes = {
  option: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.shape({
      messageType: PropTypes.string,
      message: PropTypes.string,
    })),
  }),
};
