import React from 'react';
import noop from 'lodash/noop';
import identity from 'lodash/identity';
import PropTypes from 'prop-types';
import { CustomSelect, DropDownTitle } from './nativeStyles';

const NativeSelect = ({
  value, onOptionSelect, options, renderer, keyBy, disabled, ...rest
}) => (
  <DropDownTitle disabled={disabled}>
    <CustomSelect
      {...rest}
      disabled={disabled}
      onChange={(event) => {
        const selectedOption = options.find(a => keyBy(a).toString() === event.target.value);
        onOptionSelect(selectedOption);
      }}
      value={keyBy(value)}
    >
      {options.map(singleOption => (
        <option key={keyBy(singleOption)} value={keyBy(singleOption)}>
          {renderer({ value: singleOption })}
        </option>
      ))}
    </CustomSelect>
  </DropDownTitle>
);

NativeSelect.propTypes = {
  options: PropTypes.array,
  onOptionSelect: PropTypes.func,
  value: PropTypes.string,
  renderer: PropTypes.func.isRequired,
  keyBy: PropTypes.func,
  disabled: PropTypes.bool,
};

NativeSelect.defaultProps = {
  options: [],
  onOptionSelect: noop,
  value: '',
  keyBy: identity,
  disabled: false,
};

export default NativeSelect;
