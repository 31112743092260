import React from 'react';
import PropTypes from 'prop-types';
import identity from 'lodash/identity';
import constant from 'lodash/constant';
import noop from 'lodash/noop';
import { TileSelectWrapper, TileDiv } from './styles';
import withSelect from '../withSelect';
import getIndexableProp from '../../utils/getIndexableProp';

class TileSelect extends React.PureComponent {
  defaultRenderer = ({ value }) => this.props.labelBy(value);

  renderAnchor = (option, indexable) => {
    const href = this.props.hrefBy(option);
    return (
      /* eslint-disable jsx-a11y/anchor-has-content */
      (href && href.length > 0) && <a href={href} {...getIndexableProp(indexable)} />
    );
  }

  render() {
    const {
      value,
      options,
      renderer,
      optionRenderer,
      onChange,
      keyBy,
      labelBy,
      disableBy,
      disabled,
      tileRenderer: Tile,
      hrefBy,
      indexable,
      ...rest
    } = this.props;

    const TileBody = optionRenderer || renderer || this.defaultRenderer;

    return (
      <TileSelectWrapper {...rest}>
        {
          options.map(option => (
            <Tile
              key={keyBy(option)}
              onClick={() => onChange(option)}
              selected={value === option}
              disabled={disabled || disableBy(option)}
            >
              <TileBody value={option} />
              {/* These <a> tags with preselected choices are added on each choice for seo.
                This enables crawlers to know about the multiple configuration of the one product
                - nofollow is added to temporarily disable these links for crawler */}
              {this.renderAnchor(option, indexable)}
            </Tile>
          ))
        }
      </TileSelectWrapper>
    );
  }
}

TileSelect.propTypes = {
  onChange: PropTypes.func,
  keyBy: PropTypes.func,
  labelBy: PropTypes.func,
  disableBy: PropTypes.func, // option-level
  disabled: PropTypes.bool, // component-level
  nullable: PropTypes.bool,
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.any),
  renderer: PropTypes.func,
  optionRenderer: PropTypes.func,
  tileRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  hrefBy: PropTypes.func,
  indexable: PropTypes.bool,
};

TileSelect.defaultProps = {
  onChange: noop,
  keyBy: identity,
  labelBy: identity,
  disableBy: constant(false),
  value: undefined,
  options: [],
  renderer: undefined,
  optionRenderer: undefined,
  tileRenderer: TileDiv,
  nullable: false,
  disabled: false,
  hrefBy: noop,
  indexable: false,
};

export default withSelect(TileSelect);
