import merge from 'lodash/merge';
import {
  UPDATE_CART_PRODUCT_GALLERY,
  UPDATE_CART_PRODUCT_PRICING,
  APP_MOUNT,
  UPDATE_CART_PRODUCT_CONFIGURATION,
  UPDATE_CART_PRODUCT,
} from '../actions/actionTypes';

export const initialState = {
  configuration: {
    cart: [],
    items: [],
    deliveryTime: {},
  },
  media: {},
  fulfillment: {},
  fulfillmentSelection: '',
  attributes: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CART_PRODUCT_GALLERY:
      const media = merge(
        {},
        { media: state.media },
        { media: action.media }
      );

      return {
        ...state,
        ...media,
      };
    case UPDATE_CART_PRODUCT_PRICING:
      return {
        ...state,
        pricing: { ...state.pricing, ...action.data}
      };
    case UPDATE_CART_PRODUCT_CONFIGURATION:
      return {
        ...state,
        ...action.data.product,
      };
    case UPDATE_CART_PRODUCT:
      return {
        ...state,
        ...action.data.product,
      };
    case APP_MOUNT: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
