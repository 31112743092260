import { takeLatest, select } from 'redux-saga/effects';
import {  CLEAR_PRICING } from '../../actions/actionTypes';
import safeSaga from '../safeSaga';
import { harmonyProductData } from '../common/pricing/harmonyProductData';
import { configuratorPricingLifeCycleEventBusTrigger } from '../common/pricing/configuratorPricingLifeCycleEventBus';
import { configSelector, productDetailsAttributeSelector  } from '../../selectors';

function* clearPricing(action){
  const { originalPrice, specialPrice } = action;
  const config = yield select(configSelector);
  const { pipfiguratorType } = yield select(productDetailsAttributeSelector);
  const partnerProductId = config?.partnerProductId?.toString();
  const discount = 0;
  const percent = NaN;
  const specialBuy = null;
  const someSkusHaveZeroPrice = true;

  const propProductData = harmonyProductData(originalPrice, discount, percent, specialBuy, specialPrice, someSkusHaveZeroPrice, partnerProductId, pipfiguratorType);

  configuratorPricingLifeCycleEventBusTrigger(propProductData);
}

export default function* clearPricingSaga() {
  yield takeLatest(CLEAR_PRICING, safeSaga(clearPricing));
}
