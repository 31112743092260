import React, { useState, useEffect, useMemo } from 'react';
import choiceAndSkuAvailability from '../actions/outOfStock/choiceAndSkuAvailability';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Configurator } from '@gcc/configurator';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import find from 'lodash/find';
import merge from 'lodash/merge';
import _mergeWith from 'lodash/mergeWith';
import _size from 'lodash/size';
import { useLifeCycleEventBus } from '../hooks/useLifeCycleEventBus';
import cookieUtils from 'cookie-utils';
import {
  extend, bool as boolType, params, shape,
  string as stringType, arrayOf
} from '@thd-nucleus/data-sources';
import getConfiguratorSettings from '../utils/getConfiguratorSettings';
import Layout from '../templates/configuratorTemplates/Layout';
import '../pipfigurator.scss';
import useDynamicTabTitle from '../hooks/useDynamicTabTitle';
import { mapskus } from '../actions/fulfillmentActions/parseHarmonyFulfillment';
import toCartFulfillmentMethod from '../actions/cartActions/__toCartFulfillmentMethod';
import store from '../store/store';
import { addSpecialOrderToCart } from '../actions/cartActions/addSpecialOrderToCart';
import { findOmsId } from '../utils/getOmsId';
import { mergeFulfillmentOptions } from '../utils/mergeExtensions';
import { resetConfigParamsPipFlip } from '../actions/pathActions';
import { productForDisplaySelector } from '../selectors';
import { dynamicTabTitleSelector } from '../selectors';
import { LOG_TO_NEW_RELIC } from '../actions/actionTypes';
import useQuantity from '../hooks/useQuantity';
import useNucleusData from '../hooks/useNucleusData';
import { ProductConfiguratorSkeleton } from './ProductConfiguratorSkeleton';

const ProductConfigurator = ({
  onLoadSuccess,
  onMount,
  config,
  thdStoreInfo,
  onSaveConfiguration,
  onChangeGraphData,
  afterRulesExecuted,
  afterChoiceSelectionUpdate,
  sku,
  skuType,
  bypassSkuMap,
  homeDelivery,
  attributes,
  dynamicTabTitle,
  storeNumber,
  productInfo,
  cartProduct,
  skuMap,
  pipFlip,
  // Breaking pricing in 2-tile GCC configurator products. Leave it commented so we remember not to add it back in a later merge.
  // onItemId,
  onResetConfigParamsPipFlip,
  itemIdForDisplay,
  deliveryTime,
}) => {
  const mainImageUrl = cartProduct?.media?.images?.[0]?.primaryLink;
  const items = mapskus(productInfo, config, skuMap, cartProduct?.fulfillment);
  const itemId = items.find((item) => item.isAnchor)?.itemId || config.partnerProductId;
  const storeId = thdStoreInfo.storeNumber;
  const [zipCode, setZipCode] = useState(thdStoreInfo.deliveryZip);

  // identify pipFlip
  const hasPipFlipCookie = cookieUtils.fed_ReadCookie('tnt_pipflip');
  const omsId = findOmsId();
  const isPipFlip = omsId !== itemId;

  const settings = getConfiguratorSettings(
    onLoadSuccess,
    onMount,
    config,
    thdStoreInfo.storeNumber,
    onSaveConfiguration,
    afterRulesExecuted,
    afterChoiceSelectionUpdate,
    mainImageUrl,
    sku,
    skuType,
    bypassSkuMap,
    isPipFlip,
    omsId
  );

  global.cookieUtils = cookieUtils;

  const { data } = useNucleusData({
    itemId,
    storeId,
    skuMap,
    itemIdForDisplay,
    directData: null,
    quantity: 1,
    zipCode
  });

  const {
    fulfillment = {},
  } = data?.product || {};
  const [isClientReady, setClientReady] = useState(false);
  const err = useLifeCycleEventBus('add-to-cart.error');
  const { assembleItemAtStore, assembleItemAtHome, productAddOns } = useLifeCycleEventBus('configurator.assembleItemAtStore');
  const { configuratorSubscribe, frequency } = useLifeCycleEventBus('configurator.configurator_subscribe');
  const { qty } = useLifeCycleEventBus('configurator.configurator_quantity');
  const { method } = useLifeCycleEventBus('configurator.fulfillment_changed');
  const { fireRules } = useLifeCycleEventBus('configurator.rules');
  const customATC = (cartOptions) => store.dispatch(addSpecialOrderToCart(cartOptions));
  const type = fulfillment?.fulfillmentOptions?.[0]?.services?.[0]?.type;
  const fulfillmentMethod = toCartFulfillmentMethod(type);
  const methodData = useMemo(() => method, [method]);
  const fulfillmentData = useMemo(() => type, [type]);
  const attributesData = useMemo(() => attributes, [attributes]);
  const assembleItemAtHomeData = useMemo(() => assembleItemAtHome, [assembleItemAtHome]);
  const productAddOnsData = useMemo(() => productAddOns, [productAddOns]);
  const qtyData = useMemo(() => qty, [qty]);
  const productInfoData = useMemo(() => productInfo, [productInfo]);
  const cartProductMemo = useMemo(() => cartProduct, [cartProduct]);
  const assembleItemAtStoreData = useMemo(() => assembleItemAtStore, [assembleItemAtStore]);
  const configurationErrorData = useMemo(() => skuMap?.configurationError, [skuMap?.configurationError]);
  const fireRulesData = useMemo(() => fireRules, [fireRules]);
  const cart = cartProductMemo?.configuration?.cart

  const fulfillmentLocation = (
    method === 'ShipToStore'
    || method === 'BOPIS'
    || fulfillmentMethod === 'ShipToStore'
    || fulfillmentMethod === 'BOPIS')
    ? thdStoreInfo?.storeNumber
    : thdStoreInfo?.zipcode;
  let itemDetails = [];
  if (cart && cart?.length <= 0) {
    itemDetails.push({
      storeId: storeNumber,
      zipCode: thdStoreInfo?.zipcode,
      fulfillmentMethod: method || fulfillmentMethod,
      fulfillmentLocation,
      quantity: 1,
    });
  } else {
    itemDetails = map(cart, x => {
      if (qty && (qty !== itemDetails?.[0]?.quantity) || (qty !== cart?.[0]?.quantity)) {
        const item = find(
          productInfo,
          (p) => p.itemId === x?.itemId
        );
        const amountAvailable = item?.storeInfo?.local?.inventory?.onHandQuantity;
        if (amountAvailable <= qty) {
          return {
            itemId: x.itemId,
            storeId: storeNumber,
            zipCode: thdStoreInfo?.zipcode,
            quantity: qty || x.quantity,
            fulfillmentMethod: method || fulfillmentMethod,
            fulfillmentLocation
          };
        }
      }
    });
  }

  if (!skuMap.isSpecialOrder) {
    delete cartProduct?.configuration?.cart?.[0]?.configurationId;
  }

  // CODE SMELL: molasses but req'd so fulfillmentOptions get set correctly
  // In some situations previous omsId fulfillment is leaking into current omsId
  const newData = _mergeWith(
    {},
    { product: (data?.product || {})},
    { product: cartProduct },
    (obj, src) => mergeFulfillmentOptions(obj, src)
  );

  const newPropProductData = {
    product: {
      configuration: {
        deliveryTime,
        cart: itemDetails,
        items: items
      }
    }
  };

  const parsedData = merge(
    {},
    { ...newData },
    { ...newPropProductData },
  );

  const newPropDeliveryData = {
    product: {
      fulfillment
    }
  };

  const parsedDeliveryData = merge(
    {},
    { ...newData },
    { ...newPropDeliveryData },
  );

  useEffect(() => {
    setZipCode(thdStoreInfo.deliveryZip);
  }, [thdStoreInfo.deliveryZip]);

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('buybox-zipcode-updated', (newZipCode) => {
      setZipCode(newZipCode.output);
    });

    return () => window.LIFE_CYCLE_EVENT_BUS.off('buybox-zipcode-updated');
  }, []);

  useEffect(() => {
    if(fireRulesData === true) {
      store.dispatch(choiceAndSkuAvailability({isStupidProduct: true}));
    }
  }, [fireRulesData]);

  useEffect(() => {
    if (skuMap?.isSpecialOrder) {
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.isSpecialOrder", { isSpecialOrder: true });
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.isSpecialOrderATC", { customATC });
    } else {
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.isSpecialOrder", { isSpecialOrder: false });
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.isSpecialOrderATC", { customATC: null});
    }
  }, [skuMap?.isSpecialOrder]);

  useEffect(() => {
    if (fulfillmentMethod === 'BOPIS' || fulfillmentMethod === 'ShipToHome' || fulfillmentMethod === 'DeliverFromStore' || fulfillmentMethod === 'DirectDelivery') {
      const newFulfillLocation = (
        method === 'ShipToStore'
        || method === 'BOPIS')
        ? thdStoreInfo?.storeNumber
        : thdStoreInfo?.zipcode;
      itemDetails = map(cart, x => ({
        itemId: x.itemId,
        storeId: storeNumber,
        zipCode: thdStoreInfo?.zipcode,
        quantity: qty || x.quantity,
        fulfillmentMethod: method,
        fulfillmentLocation: newFulfillLocation
      }));

      const newPropFulfilData = {
        product: {
          configuration: {
            deliveryTime,
            cart: itemDetails,
            items: items,
          }
        }
      };
      const parsedFulfillmentData = merge(
        {},
        { ...newData },
        { ... parsedDeliveryData },
        { ...newPropFulfilData }
      );

      return onChangeGraphData(parsedFulfillmentData);
    }

    if (fulfillmentMethod === 'ShipToStore') {
      if (cartProduct?.fulfillment?.fulfillmentOptions?.[0]?.services?.[0]?.type !== 'boss' ) {
        delete cartProduct?.fulfillment?.fulfillmentOptions;
      }
      let startDate;
      let endDate;
      const shippingObj = fulfillment?.fulfillmentOptions ? merge({}, fulfillment.fulfillmentOptions) : null;
      if (skuMap.isSpecialOrder) {
        startDate = deliveryTime?.startDate;
        endDate = deliveryTime?.endDate;
      }
      const shippingArr = [];
      forEach(shippingObj, x => {
        shippingArr.push(x)
      }
      )
      if (startDate && endDate) {
        forEach(shippingObj, o => {
          o.services.find(d => {
            d.deliveryDates = { startDate, endDate };
          })
        })
      }
      const newPropDeliveryData = {
        product: {
          fulfillment: {
            ...fulfillment,
            fulfillmentOptions: shippingArr
          },
          availabilityType: {
            type: null
          }
        }
      };
      const newFulfillLocation = (
        method === 'ShipToStore'
        || method === 'BOPIS')
        ? thdStoreInfo?.storeNumber
        : thdStoreInfo?.zipcode;
      itemDetails = map(cart, x => {
        const choiceQuantity = skuMap.subSkus.find(s => Number(s.sku) === x.itemId);
        const multipliedQuantity = choiceQuantity ? choiceQuantity.multiplier * qty : qty;
        return {
          itemId: x.itemId,
          storeId: storeNumber,
          zipCode: thdStoreInfo?.zipcode,
          quantity: multipliedQuantity || x.quantity,
          fulfillmentMethod: method || fulfillmentMethod,
          fulfillmentLocation: newFulfillLocation
        }
      });

      const newPropCartData = {
        product: {
          configuration: {
            deliveryTime,
            cart: itemDetails,
            items: items
          }
        }
      };

      const parsedBossData = merge(
        {},
        { product: cartProduct },
        { ...newPropDeliveryData },
        { ...newPropCartData }
      );

      if (newPropCartData?.product?.configuration?.cart !== cartProduct?.configuration?.cart) {
        return onChangeGraphData(parsedBossData);
      }
    }
    if (!fulfillmentMethod && cartProduct?.fulfillment?.fulfillmentOptions) {
      delete cartProduct?.fulfillment?.fulfillmentOptions;
      const newUnavailableData = {
        product: {
          availabliltyType: {
            type: null
          }
        }
      };

      const parsedUnavailableData = merge(
        {},
        { product: cartProduct },
        { ...newUnavailableData },
      );
      return onChangeGraphData(parsedUnavailableData);
    }
  }, [fulfillmentMethod, fulfillment?.fulfillmentOptions, fulfillmentData, methodData, productInfoData.primary, cart?.[0]?.itemId, attributesData]);

  useEffect(() => {
    mapskus(productInfo, config, skuMap, cartProduct?.fulfillment);
  }, [productInfoData.primary]);

  useEffect(() => {
    if (configuratorSubscribe ) {
      newData.product.configuration.cart[0].subscriptions = {
        ogModule: 'pdp_nocontent',
        frequency
      }

      const newPropSubscriptonData = {
        product: {
          subscription: true,
          configuration: {
            cart: newData.product.configuration.cart,
          }
        }
      };
      const parsedSubscriptonData = merge(
        {},
        { ...newData },
        { ...newPropSubscriptonData },
      );

      return onChangeGraphData(parsedSubscriptonData);
    } else {
      delete newData?.product?.configuration?.cart[0]?.subscriptions;
      newData.product.subscription = false;
      const newPropSubscriptonData = {
        product: {
          subscription: false,
          configuration: {
            cart: newData.product.configuration.cart,
          }
        }
      };
      const parsedSubscriptonData = merge(
        {},
        { ...newData },
        { ...newPropSubscriptonData },
      );
      return onChangeGraphData(parsedSubscriptonData);
    }
  }, [configuratorSubscribe, frequency]);

  useEffect(() => {
    if (!isEmpty(err)) {
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_unavailable", { configuratorUnavailable: true });
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.loading", false);
      console.log(err);
      }
  }, [err]);

  useEffect(() => {
    const warrantyItem = productAddOnsData?.warrantyItem;

    if (warrantyItem?.itemId && newData?.product?.configuration?.cart?.[0]
      && (newData?.product?.configuration?.cart?.[0]?.warrantyItem?.itemId !== warrantyItem?.itemId )) {
      newData.product.configuration.cart[0].warrantyItem = warrantyItem;
      const parsedWarrentyData = merge(
        {},
        { ...newData },
      );
      return onChangeGraphData(parsedWarrentyData);
    }

    if (!warrantyItem?.itemId && newData?.product?.configuration?.cart?.[0]?.warrantyItem?.itemId) {
      delete newData?.product?.configuration?.cart?.[0]?.warrantyItem;
      return onChangeGraphData(newData);
    }
    if (assembleItemAtHome && newData?.product?.configuration?.cart?.[0]
      && (newData?.product?.configuration?.cart?.[0]?.serviceLineItems?.lineItem?.[0]?.storeSKU
        !== productAddOnsData?.attachedLabor?.[0]?.attachedLaborSku)) {
      newData.product.configuration.cart[0].attachedLabor = productAddOnsData?.attachedLabor;
      const parsedAssembleData = merge(
        {},
        { ...newData },
      );
      if (cartProduct?.configuration?.cart?.[0]?.attachedLabor?.[0]?.attachedLaborSku
        !== productAddOnsData?.attachedLabor?.[0]?.attachedLaborSku) {
        return onChangeGraphData(parsedAssembleData);
      }

    }
    if (!assembleItemAtHome && newData?.product?.configuration?.cart?.[0]?.attachedLabor) {
      delete newData.product.configuration.cart[0].attachedLabor;
      const parsedAssembleData = merge(
        {},
        { ...newData },
      );

      return onChangeGraphData(parsedAssembleData);
    }
    if (assembleItemAtStore && newData?.product?.configuration?.cart?.[0]
      && newData.product.configuration.cart[0].assembleItemAtStore !== 'Y') {
      newData.product.configuration.cart[0].assembleItemAtStore = 'Y'
      const parsedSubscriptonData = merge(
        {},
        { ...newData },
      );

      return onChangeGraphData(parsedSubscriptonData);
    } else if (assembleItemAtStore === false && newData?.product?.configuration?.cart?.[0]?.assembleItemAtStore) {
      delete newData.product.configuration.cart[0].assembleItemAtStore;
      const parsedSubscriptonData = merge(
        {},
        { ...newData },
      );
      return onChangeGraphData(parsedSubscriptonData);
    }
  }, [assembleItemAtStoreData, assembleItemAtHomeData, productAddOnsData]);

  useEffect(() => {
    if (skuMap?.configurationError) {
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_unavailable", { configuratorUnavailable: true });
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.loading", false);
    }
  }, [configurationErrorData]);

  useEffect(() => {
    if (attributes.bypassSkuMap === 'True' || attributes.hasSkuMap === 'False') {
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.loading", false);
      return onChangeGraphData(parsedData);
    }
  }, [attributes]);

  const [isMajorAppliance, setIsMajorAppliance] = useState(true);
  const { hiddenOptions } = attributes || {};
  const showOptions = hiddenOptions !== 'True';

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator-mounted', { configurator: true });
    setClientReady(true);
    const attExists = attributes?.isMajorAppliance === 'True';
    if (!attExists && !isEmpty(attributes)) {
      setIsMajorAppliance(false);
    }

    return () => {
      LIFE_CYCLE_EVENT_BUS.off('configurator.configurator-mounted');
    };
  }, [attributes]);

  useQuantity(cart, skuMap, items, newData, onChangeGraphData, deliveryTime, qtyData, qty);

  useEffect(() => {
    setClientReady(true);
    return () => {
      LIFE_CYCLE_EVENT_BUS.off('configurator.configurator-mounted');
    };
  }, [attributes]);

  useDynamicTabTitle(dynamicTabTitle);

  const onBackPipFlip = () => {
    onResetConfigParamsPipFlip();
    pipFlip();
  };

  useEffect(() => {
    if (window.LIFE_CYCLE_EVENT_BUS !== undefined) {
      window.LIFE_CYCLE_EVENT_BUS.on('add-to-cart.error', (err) => {
        store.dispatch({
          type: LOG_TO_NEW_RELIC,
          actionName: 'add-to-cart-failure',
          payload: err,
        });
      });
    }

    // ALWAYS DISABLE DELIVERY TIMER FOR PIPFIGS
    window.LIFE_CYCLE_EVENT_BUS.trigger('configurator.disable_timer', true);

    return () => {
      window.LIFE_CYCLE_EVENT_BUS.off('add-to-cart.error');
    }
  }, []);

  if (!isClientReady) {
    return <ProductConfiguratorSkeleton />;
  }

  return (
    <div className="product-configurator">
      {pipFlip && hasPipFlipCookie && isPipFlip
        && data?.product?.info?.globalCustomConfigurator?.customExperience !== "stock-blinds-pipflip" && (
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 20,
          }}
        >
          <span
            style={{
              cursor: 'pointer',
              color: '#3E7697',
              fontSize: 14,
              paddingBottom: 10,
            }}
            onClick={onBackPipFlip}
          >
            ← Back
          </span>
        </div>
      )}
      <Configurator settings={settings}>
        <Layout homeDelivery={homeDelivery} isPipFlip={isPipFlip} showOptions={showOptions} />
      </Configurator>
    </div>
  );
};

ProductConfigurator.propTypes = {
  onMount: PropTypes.func.isRequired,
  onLoadSuccess: PropTypes.func.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  onResetConfigParamsPipFlip: PropTypes.func.isRequired,
  homeDelivery: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

// default settings just to render the components
// needs revisit once we start consuming this component since we will be gettting these from environment variables
ProductConfigurator.defaultProps = {};

ProductConfigurator.displayName = 'ProductConfigurator';

ProductConfigurator.dataModel = extend(
  {
    product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSource: stringType(),
    identifiers: shape({
      productLabel: stringType(),
    }),
  })
  },
  {
    clientOnlyProduct: params({ itemId: stringType().isRequired }).shape({
      itemId: stringType(),
      dataSources: stringType(),
      info: shape({
        gccExperienceOmsId: stringType(),
        recommendationFlags: shape({
          visualNavigation: boolType(),
          pipCollections: boolType(),
          packages: boolType(),
          ACC: boolType(),
          collections: boolType(),
          frequentlyBoughtTogether: boolType(),
          bundles: boolType(),
          batItems: boolType()
        }),
        dotComColorEligible: boolType(),
        globalCustomConfigurator: shape({
          customPosition: stringType()
        })
      }),
      identifiers: shape({
        roomVOEnabled: boolType(),
        productType: stringType(),
        skuClassification: stringType()
      }),
      availabilityType: shape({
        discontinued: boolType(),
        type: stringType()
      }),
      fulfillment: params({ storeId: stringType() }).shape({
        backordered: boolType(),
        fulfillmentOptions: arrayOf(shape({
          type: stringType(),
          fulfillable: boolType(),
          services: arrayOf(shape({
            type: stringType(),
            locations: arrayOf(shape({
              isAnchor: boolType(),
              inventory: shape({
                isLimitedQuantity: boolType(),
                isOutOfStock: boolType()
              })
            }))
          }))
        })),
      }),
    })
  },
  {
    clientOnlyProduct: params({ itemId: stringType().isRequired }).shape({
      seoDescription: stringType()
    })
  }
);

const mapStateToProps = (state) => {
  const dynamicTabTitle = dynamicTabTitleSelector(state);
  const {
    skuMap: { sku, skuType },
    configurator: {
      ajaxStatus: { isInitialized },
    },
    choiceAndSkuAvailability: { choiceAvailability },
  } = state;
  const { bypassSkuMap } = state.product.details.attributes || '';
  const {itemId} = productForDisplaySelector(state);
  const { hasServiceAddOns } = state.productInfo.base || false;

  return {
    config: state.config,
    thdStoreInfo: state.thdStoreInfo,
    sku,
    skuType,
    choiceAvailability,
    isInitialized,
    bypassSkuMap,
    dynamicTabTitle,
    itemIdForDisplay: itemId,
    hasServiceAddOns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetConfigParamsPipFlip: () => dispatch(resetConfigParamsPipFlip()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductConfigurator);
