import apiGetGccProductDetails from '../api/getGccProductDetails';
import { UPDATE_GCC_PRODUCT_DETAILS, UPDATE_GCC_PRODUCT_DETAILS_SUCCESS } from './actionTypes';
import { createAction } from '../utils/createAction';

// actions
const updateGccProductDetails = createAction(UPDATE_GCC_PRODUCT_DETAILS, 'productDetails');
export const updateGccProductDetailsSuccess = createAction(UPDATE_GCC_PRODUCT_DETAILS_SUCCESS);

// thunk
export default () => (dispatch, getState) => {
  const { partnerProductId, siteId } = getState().config;

  return apiGetGccProductDetails(partnerProductId, siteId).then((productDetails) => {
    dispatch(updateGccProductDetails(productDetails));
    dispatch(updateGccProductDetailsSuccess());
  });
};
