import styled, { css } from 'styled-components';

export const SwatchSpan = styled.span`
  position: relative;
`;

export const SwatchRoot = styled.li`
  display: inline-block;
  border: 1px solid #ccc;
  margin: 0 10px 10px 0;
  width: 40px;
  height: 40px;
  cursor: pointer;

  & > span {
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  ${props => css`
    width: 40px;
    height: 40px;
    padding: 3px;

    &,
    img {
      border-radius: 3px;
    }

    ${props.selected && css`
      border: 2px solid #F96302;
    `}
  `}
`;
