import styled, { css } from 'styled-components';

export const mediaQuery = css`
  @media screen and (max-width: 639px) {
    display: none;
  }
`;

/** Will render its children on mobile devices, but they'll be under a `display: none` div */
export default styled.div`${mediaQuery}`;
