import _get from 'lodash/get';
import uniq from 'lodash/uniq';
import axios from 'axios';
import castArray from 'lodash/castArray';
import trim from 'lodash/trim';
import config from '../config';
import toCamelCase from '../utils/camelCase';
import productFromGraph from './graphQL/queries/productFromGraph';
import { thdApiMap } from './graphQL/mapping/thdApiMapping';

const castProductPropertyToArray = (responseData) => {
  const product = castArray(responseData.products.product);
  return {
    products: {
      product,
    },
  };
};

export const getThdProductDetails = (skuNumbers, storeId) => {
  const itemId = castArray(skuNumbers).join(',');
  let zipCode = '30339';
  if (typeof window !== 'undefined' && window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails) {
    zipCode = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails()?.zipcode;
  }

  const params = {
    itemId: config.useCatalogGraphql ? uniq(skuNumbers): itemId,
    storeId,
    environment: trim(config.thdApiEnvironmentMap) || undefined,
    key: config.thdApiKey,
    show: 'pricing,inventory,info,shipping,media',
    showLowestPrice: true,
    zipCode
  };


  if (config.useCatalogGraphql) {
    const urlGraph = `${config.graphThdApiBaseUrl}/federation-gateway/graphql`;
    const promise = productFromGraph(urlGraph, params.itemId, params.storeId, params.zipCode);
    return promise
      .then(response => thdApiMap(response))
      .then(castProductPropertyToArray)
      .then(toCamelCase);
  } else {
    const url = `${config.thdApiBaseUrl}/v3/catalog/products/sku`;
    const promise = axios.get(url, { params });

    return promise
      .then(response => response.data)
      .then(castProductPropertyToArray)
      .then(toCamelCase);
  }
};
