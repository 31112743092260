import styled from 'styled-components';
import COLOR from './_color';

const ButtonLink = styled.button`
  color: ${COLOR};
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  font-size: ${props => props.linkFontSize};

  &:hover {
    text-decoration: underline;
  }
`;

export default ButtonLink;
