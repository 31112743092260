export default function triggerProductChosen(itemId, prevConfiguredItemId) {
  let newConfiguredItemId = prevConfiguredItemId;

    newConfiguredItemId = itemId;
    const configuredItemId = itemId;
    LIFE_CYCLE_EVENT_BUS.trigger("configurator.stock_product_chosen_event", { configuredItemId });
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger("configurator.stock_product_chosen_lifecycle_event", { configuredItemId });

  return newConfiguredItemId;
}
