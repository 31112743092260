import axios from 'axios';

const productGraph = `query productGraph
($itemIds: [String!]!, $dataSource: String, $storeId: String, $zipCode: String) {
  products(itemIds: $itemIds, dataSource: $dataSource) {
    itemId
      identifiers {
        itemId
        isSuperSku
        parentId
        canonicalUrl
        brandName
        modelNumber
        productLabel
        productType
      }
      badges {
        name
      }
      media {
      images{
        sizes
        subType
        type
        url
      }
      video {
        dashURL,
        link{
          text
          url
        }
        longDescription
        shortDescription
        thumbnail
        title
        type
        url
        videoStill
        videoId
        xMpegURL
      } }
      details{
      description
    }
    info{
    classNumber
    subClassNumber
    productDepartment
    quantityLimit
    hasServiceAddOns
  }
    availabilityType {
      discontinued
      status
      type
      buyable
    }
  reviews {
    ratingsReviews {
      averageRating
      totalReviews
    }
  }

    pricing(storeId: $storeId) {
      promotion {
        dates {
          end
          start
        }
        type
        description {
          shortDesc
          longDesc
        }
        dollarOff
        percentageOff
        savingsCenter
        savingsCenterPromos
        specialBuySavings
        specialBuyDollarOff
        specialBuyPercentageOff
        experienceTag
        subExperienceTag
        anchorItemList
        itemList
      }
      value
      alternatePriceDisplay
      alternate {
        bulk {
          pricePerUnit
          thresholdQuantity
          value
          __typename
        }
        unit {
          caseUnitOfMeasure
          unitsOriginalPrice
          unitsPerCase
          value
          __typename
        }
        __typename
      }
      original
      mapAboveOriginalPrice
      message
      preferredPriceFlag
      specialBuy
      unitOfMeasure
      __typename
    }

    fulfillment(storeId: $storeId, zipCode: $zipCode) {
      fulfillmentOptions {
        type
        services {
          type
          locations {
            isAnchor
            inventory {
              isLimitedQuantity
              isOutOfStock
              isInStock
              quantity
              isUnavailable
              maxAllowedBopisQty
              minAllowedBopisQty
              __typename
            }
            type
            storeName
            locationId
            curbsidePickupFlag
            isBuyInStoreCheckNearBy
            distance
            state
            storePhone
            __typename
          }
          deliveryTimeline
          deliveryDates {
            startDate
            endDate
            __typename
          }
          deliveryCharge
          dynamicEta {
            hours
            minutes
            __typename
          }
          hasFreeShipping
          freeDeliveryThreshold
          totalCharge
          __typename
        }
        fulfillable
        __typename
      }
      anchorStoreStatus
      anchorStoreStatusType
      backorderedShipDate
      backordered
      bossExcludedShipStates
      sthExcludedShipState
      bossExcludedShipState
      excludedShipStates
      seasonStatusEligible
      onlineStoreStatus
      onlineStoreStatusType
      inStoreAssemblyEligible
      bodfsAssemblyEligible
    }
  }
}`;

const productFromGraph = (graphlUrl, itemId, myStoreId, zipCode) => {
  return axios
    .post(graphlUrl,{ query: productGraph, variables: { storeId: myStoreId, itemIds: itemId, zipCode } }, {
      headers: {
        'x-experience-name': 'storm-doors-online-m',
      },
    })
    .then(response => response.data);
};


export default productFromGraph;
