import { useEffect } from 'react'

function useDynamicTabTitle(dynamicTabTitle) {

  useEffect(() => {
    if (dynamicTabTitle) {
      document.title = dynamicTabTitle;
    }
  }, [dynamicTabTitle]);
}

export default useDynamicTabTitle
