import React from 'react';
import PropTypes from 'prop-types';
import getIndexableProp from '../../utils/getIndexableProp';

const Option = ({ href, indexable, children }) => (
  <span className="drop-down__hover-effect">
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a className="enabled" href={href} onClick={e => e.preventDefault()} {...getIndexableProp(indexable)}>{children}</a>
  </span>
);

Option.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  indexable: PropTypes.bool,
  href: PropTypes.string,
};

Option.defaultProps = {
  indexable: false,
  href: '',
};

export default Option;
