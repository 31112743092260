import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import ModalWithHeader from '../ModalWithHeader';
import Portal from '../Portal';
import { MeasureContainer, Definition } from './styles';
import Markdown from '../Markdown';


const HowToMeasure = ({
  label,
  definitionHeader,
  definitionMarkdown,
  definition: Def,
}) => {
  const modalHeader = definitionHeader || label;
  const renderedDefinition = isFunction(Def) ? <Def /> : Def;
  const [showModal, setShowModal] = useState(false);
  const closeModal = useCallback(() => {
    setShowModal(false);
  });

  const openModal = useCallback(() => {
    setShowModal(true);
  });

  return (
    <React.Fragment>
      <MeasureContainer>
        <b>Enter your measurements:</b><br />
        How do I measure? <a href="#/" onClick={openModal}>Learn More</a>
      </MeasureContainer>
      {
       showModal &&
       <Portal>
         <ModalWithHeader header={modalHeader} onClose={closeModal}>
           {renderedDefinition ||
           <Definition>
             <Markdown source={definitionMarkdown} />
           </Definition>}
         </ModalWithHeader>
       </Portal>
      }
    </React.Fragment>
  );
};


HowToMeasure.propTypes = {

  definitionHeader: PropTypes.string,
  definitionMarkdown: PropTypes.string,
  definition: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  label: PropTypes.string,

};

HowToMeasure.defaultProps = {
  definitionHeader: '',
  definitionMarkdown: '',
  definition: null,
  label: '',

};

export default HowToMeasure;
