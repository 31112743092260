import { createCartConfiguration } from '../fulfillmentActions/createCartConfiguration';
import { mapskus } from '../fulfillmentActions/parseHarmonyFulfillment';
import { addGccConfigurationToCart } from './';
import { executeRules } from '@gcc/configurator/dist/actions/rulesActions';
import addConfigurationToCartV2 from '../cartActions/addConfigurationToCartV2';
import { cartProductFulfillmentSelector } from '../../selectors';

export const addSpecialOrderToCart = (cartOptions) => (dispatch, getState) => {
    const state = getState();
    const {
        productInfo,
        config,
        skuMap,
    } = state;

    const fulfillment = cartProductFulfillmentSelector(getState());

    // escape hatch to eject to carting stock products
    // there is a scenario where cart drawers have customATC true but they
    // SHOULD NOT so if customer gets into this state let them checkout
    if (!skuMap?.isSpecialOrder) {
      return dispatch(addConfigurationToCartV2());
    }

    const items = mapskus(productInfo, config, skuMap, fulfillment);
    Promise.resolve(dispatch(executeRules()))
      .then((validation) => {
        console.log('validation: ', validation)
        // const isInvalid = validation.data.messages.some(m => m.type === 'Error');
        // return runTasks(isInvalid ? doErrorTasks : doSaveConfigurationTasks);
        return Promise.resolve(createCartConfiguration({ overrideStoreNumber: cartOptions?.storeId }))
      })
      .then((res) => {
        // LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_available_items", { items });
        if (res && res.status === 200) {
          dispatch(addGccConfigurationToCart(res.data.ConfigurationGuid, items, cartOptions));
        }
      })
      .catch(error => {
        console.log('error: ', error)
      });
  };
