import { createAction } from '../../utils/createAction';
import {
  CART_SAVE_SUCCESS,
  CART_MODAL_HIDE,
  CART_SAVE_STARTED,
  CART_SAVE_FAILED,
  CART_RESET,
  UPDATE_FULFILLMENTMETHOD,
  SEND_ATC_FROM_BUYBOX_EVENT_TO_ANALYTICS,
  SEND_PAYMENT_ESTIMATOR_EVENT_TO_ANALYTICS,
  SEND_AVAILABILITY_MODAL_LOAD_EVENT_TO_ANALYTICS,
  SEND_CHECK_AVAILABILITY_EVENT_TO_ANALYTICS,
  SET_IS_APP_LOAD_ANALYTICS_DONE,
} from '../actionTypes';

export const cartOperationSuccess = (cartData, itemDetails) => ({
  type: CART_SAVE_SUCCESS,
  cartData,
  itemDetails,
});

export const displayCartModal = {
  type: CART_SAVE_STARTED,
};

export const hideCartModal = {
  type: CART_MODAL_HIDE,
};

export const resetCart = createAction(CART_RESET);

export const cartOperationFailed = cartError => ({
  type: CART_SAVE_FAILED,
  cartError,
});

export const updateFulfillmentMethod = fulfillmentMethod => ({
  type: UPDATE_FULFILLMENTMETHOD,
  fulfillmentMethod,
});

export const sendAtcFromBuyBoxEventToAnalytics = (cartData, itemDetails, logEventsInConsole) => ({
  type: SEND_ATC_FROM_BUYBOX_EVENT_TO_ANALYTICS,
  cartData,
  itemDetails,
  logEventsInConsole,
});

export const sendPaymentEstimatorEventToAnalytics = {
  type: SEND_PAYMENT_ESTIMATOR_EVENT_TO_ANALYTICS,
};

export const sendAvailabilityModalLoadEventToAnalytics = {
  type: SEND_AVAILABILITY_MODAL_LOAD_EVENT_TO_ANALYTICS,
};

export const sendCheckAvailabilityEventToAnalytics = response => ({
  type: SEND_CHECK_AVAILABILITY_EVENT_TO_ANALYTICS,
  payload: response,
});

export const setIsAppLoadAnalyticsDone = isAppLoadAnalyticsDone => ({
  type: SET_IS_APP_LOAD_ANALYTICS_DONE,
  isAppLoadAnalyticsDone,
});

