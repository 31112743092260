import { takeLatest, select, put } from 'redux-saga/effects';
import safeSaga from '../safeSaga';
import { GET_PRICING, GET_PRICING_MSRP, GET_PRICING_SO, PERSIST_CHOICES_ON_LOAD, UPDATE_GCC_PRODUCT_DETAILS_SUCCESS, UPDATE_SKUMAP } from '../../actions/actionTypes'
import { productDetailsAttributeSelector } from '../../selectors';
import {  persistChoicesOnLoad } from '@gcc/configurator';
import { sendHiddenOption } from '../../actions/fulfillmentActions/createCartConfiguration';
import _isEmpty from 'lodash/isEmpty';
import { CUSTOM_CONFIG_BASED } from '../../utils/pipFiguratorType';
import { getPricing } from '../../actions/pricingActions';

function* persistChoices() {
  // This saga ensures the following value is set in state on page load
  //     state => configurator => savedProductChoices
  const { pipfiguratorType } = yield select(productDetailsAttributeSelector) || '';
  if (pipfiguratorType && pipfiguratorType !== CUSTOM_CONFIG_BASED) {
    sendHiddenOption();
  }
}

function* getPricingAfterProductDetailsHydrated() {
  yield put(getPricing());
}

export default function* persistChoicesSaga() {
  yield takeLatest([GET_PRICING, GET_PRICING_MSRP, UPDATE_GCC_PRODUCT_DETAILS_SUCCESS], safeSaga(persistChoices));
  yield takeLatest([UPDATE_GCC_PRODUCT_DETAILS_SUCCESS], safeSaga(getPricingAfterProductDetailsHydrated));
}