import { select, put, call, all } from 'redux-saga/effects';
import { getMsrpPricingFromAutobahn } from '../../api/eleanorApi';
import { updateProductInfo } from '../../actions/pricingActions';
import {
  configSelector,
  productDetailsSelector,
  configuratorSelector,
} from '../../selectors';
import getThdProductDetailsOrNotItsCool from './_getThdProductDetailsOrNotItsCool';
import {
  getSelectedChoices,
  getPriceData,
} from './_utils';

const getOptionType = (parentOptionType, tags) => {
  if (tags.includes('custom-size-width')) {
    return 'Width';
  } if (tags.includes('custom-size-height')) {
    return 'Height';
  }

  return parentOptionType;
};
function* getMsrpPricing(skuMap, storeNumber, options, choices) {
  const { productInfo } = yield select(configuratorSelector);
  const { productId } = productInfo;
  const { siteId, partnerProductId } = yield select(configSelector);
  const { productLineId } = yield select(productDetailsSelector);

  const data = {
    siteId,
    productId,
    partnerProductId,
    productLineId,
    choices: getSelectedChoices(options, choices)
      .map((x) => ({
        optionId: x.optionId,
        choiceId: x.id,
        choiceValue: x.value,
        tags: x.tags,
        optionType: getOptionType(x.parentOptionType, x.tags),
      })),
  };

  const [pricing, thdDetails] = yield all([
    call(getMsrpPricingFromAutobahn, data),
    call(getThdProductDetailsOrNotItsCool, [partnerProductId], storeNumber),
  ]);
  const newSkus = {
    ...skuMap,
    sku: partnerProductId.toString(),
  };

  yield put(updateProductInfo({ newSkus, thdDetails }));

  return getPriceData(pricing.originalPrice, pricing.realPrice);
}

export default getMsrpPricing;
