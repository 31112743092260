import axios from 'axios';
import config from '../config';
import toCamelCase from '../utils/camelCase';

const saveRecoverableFailure = validationMessages => ({
  type: 'GCC_CONFIGURATOR/SAVE_RECOVERABLE_FAILURE',
  validationMessages,
});
export const mapChoicesToSkus = (siteId, productId, storeId, choices) => {
  const url = `${config.eleanorBaseUrl}/api/MapChoicesToSkusWithMultipliers`;

  const json = {
    siteId,
    productId,
    storeId,
    choices,
  };

  return axios
    .post(url, json)
    .then((response) => response.data)
    .then(toCamelCase)
    .catch((error) => {
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_unavailable", { configuratorUnavailable: true });
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.loading", false);
      if (error.name && error.name === 'SaveError') {
        dispatch(saveRecoverableFailure(error.validationErrors));
      }
      throw error;
    });
};

export const getSkuPricing = (sku, skuType, productId) => {
  const url = `${config.eleanorBaseUrl}/pricing/GetRetailPriceAndCost/sku/${sku}/skuType/${skuType}/productId/${productId}`;
  const promise = (sku && skuType && productId) ? axios.get(url, '') : Promise.resolve;

  return promise
    .then((response) => response.data);
};

export const mapChoiceSkuAvailability = (productId, storeId) => {
  const url = `${config.eleanorBaseUrl}/api/CheckProductChoiceAndSkuAvailability`;
  const params = { productId, storeId };
  const promise = axios.get(url, { params });
  return promise
    .then((response) => response.data);
};
