import { getPathFromSelectedChoices, getProtocolWithHost, getChoicePaths, clearPersistedChoices } from '../utils/pathUtils';
import { UPDATE_PATH } from './actionTypes';

export const updatePath = () => (dispatch, getState) => {
  const { configurator, config: { isCanonicalUrlSelfReferenced } } = getState();

  const url = getPathFromSelectedChoices(configurator);
  window.history.replaceState(
    null,
    document.title,
    url,
  );

  if (isCanonicalUrlSelfReferenced) {
    dispatch({
      type: UPDATE_PATH,
      url: `${getProtocolWithHost()}${url}`,
    });
  }
};

export const resetConfigParamsPipFlip = () => (dispatch, getState) => {
  const { configurator } = getState();
  const { options, choices } = configurator;
  const selectedChoicesToRemove = getChoicePaths(options, choices).join('&');
  const currentParamUrl = getPathFromSelectedChoices(configurator);
  let newBackUrl = currentParamUrl.replace(selectedChoicesToRemove, '');

  if (newBackUrl.endsWith('&') || newBackUrl.endsWith('?')) {
    newBackUrl = newBackUrl.slice(0, -1);
  }

  history.pushState(null, null, newBackUrl);
  dispatch(clearPersistedChoices());
}
