import merge from 'lodash/merge';
import flatMap from 'lodash/flatMap';
import find from 'lodash/find';
import {
  productsForCartSelector,
} from '../../selectors';
import { UPDATE_CART_PRODUCT_CONFIGURATION } from '../actionTypes';
import { mapskus } from '../fulfillmentActions/parseHarmonyFulfillment';
import toCartFulfillmentMethod from './__toCartFulfillmentMethod';
import { cartProductSelector } from '../../selectors';

const addConfigurationToCart = (stores) => (dispatch, getState) => {
  const state = getState();
  const {
    thdStoreInfo,
    productInfo,
    config,
    skuMap,
    skuMap: {
      subSkus,
    },
  } = state;
  const product = cartProductSelector(getState());

  const { fulfillment, configuration } = product;
  const items = mapskus(productInfo, config, skuMap, fulfillment);

  const addTHDProductToCart = () => {
    const { allowDuplicateSubSkus, isMajorAppliance } = state.product.details.attributes;
    const createItemDetailsPerStore = (cartProduct) => {
      // Adds the ability to cart Major Appliances
      let keys = Object.keys(productInfo);
      const excludeItemKeys = ["DefaultProduct"];
      keys = keys.filter((item) => !excludeItemKeys.includes(item));
      let fulfillmentType = configuration?.cart?.[0]?.fulfillmentMethod;
      keys.forEach(key => {
        if (cartProduct?.itemId === productInfo[key]?.itemId) {
          const fulfillmentOptions = productInfo[key].storeInfo?.local?.fulfillmentOptions;
          if (!fulfillmentOptions || fulfillmentOptions.length === 0) {
            fulfillmentType = undefined;
          } else {
            // let's check if current selected fulfillmentType exists in the product, if not default to first available
            const toCartListReturnedFromOptions = Object.keys(fulfillmentOptions).map((option) =>
              toCartFulfillmentMethod(option)
            );
            const fulfillmentMatch = toCartListReturnedFromOptions.find(
              (option) => option === fulfillmentType
            );

            fulfillmentType =
              fulfillmentMatch || toCartFulfillmentMethod(Object.keys(fulfillmentOptions)?.[0]);
          }
        }
      });

      if (isMajorAppliance) {
        fulfillmentType = 'DirectDelivery';
      }
      if (!fulfillmentType) {
        return undefined;
      }

      const choiceQuantity = subSkus.find(x => Number(x.sku) === cartProduct.itemId);
      const cartQuantity = configuration?.cart?.[0]?.quantity || 1;
      const multipliedQuantity = choiceQuantity ? choiceQuantity.multiplier * cartQuantity : cartQuantity;
      if (choiceQuantity && !isMajorAppliance) {
        const item = find(
          productInfo,
          (p) => p.itemId === cartProduct.itemId
        );
        const amountAvailable = item?.storeInfo?.local?.inventory?.onHandQuantity
        const anchorSku = items.find(i => i.isAnchor === true);
        const isSplitFulfillment = anchorSku?.itemId !== item?.itemId;
        let fulfillmentTypes = [];
        fulfillment?.fulfillmentOptions?.forEach(
          x => x.services.forEach(
            i => fulfillmentTypes.push(i.type)
          )
        );
        const type = fulfillmentTypes.filter(t => t !== fulfillmentType?.toLowerCase())?.[0]
        if (multipliedQuantity > amountAvailable && isSplitFulfillment) {
          const convertedType = toCartFulfillmentMethod(type);
          fulfillmentType = convertedType;
        }
      };
      const orderLocation = (fulfillmentType === 'ShipToStore' || fulfillmentType === 'BOPIS') ? thdStoreInfo?.storeNumber : thdStoreInfo?.zipcode;

      const itemData = {
        itemId: `${cartProduct.itemId}`,
        storeId: thdStoreInfo?.storeNumber,
        zipCode: thdStoreInfo?.zipcode,
        fulfillmentMethod: fulfillmentType,
        fulfillmentLocation: orderLocation,
        quantity: multipliedQuantity,
      };

      if (allowDuplicateSubSkus) {
        itemData.applianceDeliveryStore = cartProduct.storeInfo.local.storeId;
      }

      return stores.map((store) => ({
        ...itemData,
        ...store,
      }));
    };

    const cartProducts = () => {
      const cartData = productsForCartSelector(state);
      if (allowDuplicateSubSkus === 'True') {
        const skuProducts = [];
        state.skuMap.subSkus.forEach((s) => {
          const item = cartData.find((c) => c.itemId === Number(s.sku));
          if (item !== undefined) {
            skuProducts.push(item);
          }
          return skuProducts;
        });
        return skuProducts;
      }
      return cartData;
    };
    const unavailableItemDetails = [];
    const mappedItemDetails = flatMap(cartProducts(), createItemDetailsPerStore).filter(x => x);
    mappedItemDetails?.forEach(x => x.storeId === thdStoreInfo?.storeNumber);
    items.forEach(x => {
      unavailableItemDetails.push({
        itemId: `${x.itemId}`,
        storeId: thdStoreInfo?.storeNumber,
        zipCode: thdStoreInfo?.zipcode,
        quantity: 1,
        fulfillmentMethod: isMajorAppliance ? 'DirectDelivery' : configuration?.cart?.[0]?.fulfillmentMethod
      });
    })

    const itemDetails = mappedItemDetails?.length > 0
    ? mappedItemDetails
    : unavailableItemDetails;
    const cart = configuration?.cart;

    if (cart !== itemDetails && itemDetails.length > 0) {
      items?.length !== cart?.length ? delete configuration?.cart : false;
      const itemIdsThatHveFulfillment = mappedItemDetails.map(mappedItem => Number(mappedItem.itemId));
      const itemsWithFulfillment = items.filter(item => itemIdsThatHveFulfillment.indexOf(item.itemId) >= 0);

      const parsedData = merge(
          {},
          { product },
          { product: {
            configuration: {
              deliveryTime:  configuration?.deliveryTime,
              cart: itemDetails,
              items: itemsWithFulfillment,
            }
          }
        }
      );

      LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_data", { configuratorData: parsedData });
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.loading", false);

      return Promise.resolve(dispatch({
        type: UPDATE_CART_PRODUCT_CONFIGURATION,
        data: parsedData,
      }));
    }
  };

  return addTHDProductToCart();
};

const withLogging = (stores = [{}]) => {

  return addConfigurationToCart(stores);
};

export default withLogging;
