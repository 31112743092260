import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { localize } from '../utils/setupLocalization';
import ProductConfigurator from './index';
import store from '../store/store';
import appMount from '../actions/appMount';
import { getchoiceDefault } from
  '../actions/outOfStock/choiceAndSkuAvailabilityAction';
import { setInitialConfig } from '../actions/configAction';
import { injectedConfiguratorSettings } from '../config';

store.dispatch(appMount());

const ProductConfiguratorWrapper = (props) =>  {
  const [isClientReady, setClientReady] = useState(false);

  useEffect(() => {
    store.dispatch(setInitialConfig(injectedConfiguratorSettings(props.itemId)))
    setClientReady(true);
  }, []);

  if (!isClientReady) { return null; }

  localize();

  return (
    <Provider store={store}>
      <ThemeProvider theme={{ scheme: 'nucleus' }}>
        <ProductConfigurator {...props} />
      </ThemeProvider>
    </Provider>
  );
}



store.dispatch(getchoiceDefault());

export { ProductConfiguratorWrapper };
