import { takeLatest, put } from 'redux-saga/effects';
import safeSaga from '../safeSaga';
import _isEmpty from 'lodash/isEmpty';
import { recheckSkuConfigurationError } from '../../actions/getSkuMap/index';

function* checkForErrors() {
  yield put(recheckSkuConfigurationError());
}

export default function* skuMapConfigurationErrorSaga() {
  yield takeLatest("GCC_CONFIGURATOR/EXECUTE_RULES_SUCCESS", safeSaga(checkForErrors));
}