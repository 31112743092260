import { select, takeLatest } from "redux-saga/effects";
import {
  UPDATE_PRODUCTINFO,
} from "../../actions/actionTypes";
import productForDisplaySelector from '../../selectors/productInfo/productForDisplaySelector';
import { productDetailsSelector } from '../../selectors/product/productDetailsSelector';
import productInfoSelector from "../../selectors/productInfo/productInfoSelector";
import triggerProductChosen from '../../utils/triggerProductChosen';
import safeSaga from "../safeSaga";

let previousConfiguredItemId = null;

function* productInfoData(action) {
  switch (action.type) {
    case UPDATE_PRODUCTINFO: {
      let { itemId } = yield select(productForDisplaySelector);
      if (itemId === -1) {
        const { primary } = yield select(productInfoSelector);
        if (primary) {
          itemId = primary.itemId;
        } else {
          const { partnerProductId } = yield select(productDetailsSelector);
          itemId = partnerProductId;
        }
      }

      if (itemId === -1) {
        itemId = null;
      }

      previousConfiguredItemId = triggerProductChosen(String(itemId), String(previousConfiguredItemId));

      break;
    }

    default:
  }
}

export default function* productInfoSaga() {
  if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {

    LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_unavailable", { configuratorUnavailable: true });
    LIFE_CYCLE_EVENT_BUS.trigger("configurator.loading", true);
  }

  yield takeLatest(
    [
      UPDATE_PRODUCTINFO,
    ],
    safeSaga(productInfoData)
  );
}
