/* This template mostly is the copy from  Eughteenth inch Drop down  Component */

import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import camelCase from 'lodash/camelCase';
import HowToMeasure from '../../components/HowToMeasure';
import ChoiceSectionHeaderV2 from '../../components/ChoiceSectionHeader/v2';
import store from '../../store/store';
import filter from 'lodash/filter';

const EighthInchDropdownWrapper = styled.div`
  margin: -10px -10px 0px -10px;
  @media (max-width: 767px) {
    div[class^="styles__Wrapper"] {
      min-width: 50%;
      box-sizing: border-box;
    }
  }
`;

function WithCustomSizing(DropDownComponent, templateName = "SixteenInchDropdown") {
  function CustomSize({
    attributes,
    option,
    choices: [choice],
    onChoiceSelected,
    onChoiceSelectedSuccess
  }) {
    const getDefinition = () => attributes[camelCase('HowToMeasure')];// Create bool from string
    const { defaultSizesWidthHeight = '' } = attributes || '';

    const [defaultWidth, defaultHeight] = defaultSizesWidthHeight && defaultSizesWidthHeight.split(':');

    const handleChange = useCallback((inch, eighth) => {
      const choiceValue = (Number.isNaN(inch) ? Number(choice.minValue) : inch)
      + (Number.isNaN(eighth) ? Number(0) : eighth);

      // Adding logic to select same hidden width choice which is selected from UI dropdown for stock blinds
      if (option.presentationName === 'EighthInchDropdown') {
        const { configurator : { options, choices } } = store.getState();
        const hiddenWidthOption = filter(options, option => option.optionType === 'HiddenWidth');
        if (hiddenWidthOption.length > 0){
          let isFoundChoiceValue = false;
          for(const choice of Object.entries(choices)) {
            if (choice[1].optionId === hiddenWidthOption[0].id && (choice[1].value === `_${choiceValue}`) && !choice[1].isSelected) {
              isFoundChoiceValue = true;
              onChoiceSelected(hiddenWidthOption[0].id, choice[1].id, choice[1].value).then(() => onChoiceSelectedSuccess())
            }
          }
          if (!isFoundChoiceValue) {
            for(const choice of Object.entries(choices)) {
              if (choice[1].optionId === hiddenWidthOption[0].id && (choice[1].value === `_${inch}`) && !choice[1].isSelected) {
                isFoundChoiceValue = true;
                onChoiceSelected(hiddenWidthOption[0].id, choice[1].id, choice[1].value).then(() => onChoiceSelectedSuccess())
              }
            }
          }
        }
      }

      onChoiceSelected(option.id, choice.id, choiceValue).then(() => onChoiceSelectedSuccess())
    }, [option, choice, onChoiceSelected]);

    // width
    useEffect(() => {
      if (option.optionType === 'Width') {

        const defaultWidthOrMinValue = defaultWidth || choice.minValue;

        if (+(choice.value) > 0 && +(choice.value) !== +(choice.minValue)) {
          Promise.resolve(onChoiceSelected(option.id, choice.id, choice.value));
          return () => false;
        }

        if (option.presentationName === 'EighthInchDropdown') {
          const { configurator : { options, choices } } = store.getState();
          const hiddenWidthOption = filter(options, option => option.optionType === 'HiddenWidth');
          if (hiddenWidthOption.length > 0){
            const selectedChoice = filter(choices, choice => choice.optionId === hiddenWidthOption[0].id && choice.isSelected === true)
            if (selectedChoice.length > 0){
              Promise.resolve(onChoiceSelected(option.id, choice.id, +(selectedChoice[0].value.replace('_', ''))).then(() => onChoiceSelectedSuccess()));
            } else {
              Promise.resolve(onChoiceSelected(option.id, choice.id, +(defaultWidthOrMinValue)).then(() => onChoiceSelectedSuccess()));
            }
          }
        } else {
          Promise.resolve(onChoiceSelected(option.id, choice.id, +(defaultWidthOrMinValue)).then(() => onChoiceSelectedSuccess()));
        }
      }
    }, [defaultWidth]);

    // height
    useEffect(() => {
      if (option.optionType === 'Height') {
        const defaultHeightOrMinValue = defaultHeight || choice.minValue;

        if (+(choice.value) > 0 && +(choice.value) !== +(choice.minValue)) {
          Promise.resolve(onChoiceSelected(option.id, choice.id, choice.value));
          return () => false;
        }

        Promise.resolve(onChoiceSelected(option.id, choice.id, +(defaultHeightOrMinValue)));
      }
    }, [defaultHeight]);

    let wholeDropdown;
    let fractionalDropdown;
    let singleLabelOnly = true;
    if(templateName === 'EighthInchDropdown'){
      const label = (option && option.shortLabel) || ''
      const value = (choice && choice.value) || 0
      wholeDropdown = {label, value: parseInt(value)};
      fractionalDropdown = {label: label.replace('(in.)', '(eighths)'), value: value - parseInt(value)};
      singleLabelOnly = false;
    }

    return (
      <React.Fragment>
        {option && (option.optionType === 'Width' || option.tags.includes("HowToMeasure")) && (
          <HowToMeasure
            label={option && option.shortLabel}
            definitionHeader="How to Measure:"
            definitionMarkdown={getDefinition()}
          />
        )}
        <div style={{ marginTop: '20px' }}>

          {singleLabelOnly && <ChoiceSectionHeaderV2 label={option && option.shortLabel} />}

          {/* Styling to adjust left and right margins */}
          <EighthInchDropdownWrapper>
            <DropDownComponent
              value={choice.value}
              minValue={Math.floor(choice.minValue)}
              maxValue={Math.floor(choice.maxValue)}
              onChange={handleChange}
              whole={wholeDropdown}
              fractional={fractionalDropdown}
            />
          </EighthInchDropdownWrapper>
        </div>
      </React.Fragment>
    );
  };

  CustomSize.propTypes = {
    option: PropTypes.object.isRequired,
    choices: PropTypes.array.isRequired,
    attributes: PropTypes.object.isRequired,
    onChoiceSelected: PropTypes.func.isRequired,
  };

  return CustomSize;
}

export default WithCustomSizing;
