import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import templates from '../templates/configuratorTemplates';
import {
  createConfiguration,
  createConfigurationWithSkuPricing,
  editConfiguration,
} from '../api/configuratorSettings';
import { getParamsFromUrl, getPreconfiguration } from './pathUtils';
import { executeAllRules } from '../api/eleanorApi';

const getHeightAndWidthByTag = choices => filter(choices, a => a.tags.includes('custom-size-width') ||
  a.tags.includes('custom-size-height'));

// for when selections are in the form of an array
export const appendHeightAndWidthToSelections = (selections, options, choices) => {
  const heightAndWidthSelections = getHeightAndWidthByTag(choices)
    .map((choice) => {
      // Code Smell:  Mapping logic copied from configurator
      const option = options[choice.optionId];
      if (option === null) {
        throw new Error(`Error building selections: can't find option with ID:${choice.optionId}`);
      }

      return {
        choiceId: choice.id,
        choiceValue: choice.value,
        choiceName: choice.internalName,
        optionId: choice.optionId,
        optionTags: option.tags,
        optionName: option.internalName,
        optionType: option.optionType,
      };
    });

  return uniqBy([...selections, ...heightAndWidthSelections], 'choiceId');
};

// for when selections are in the form of a dictionary
export const updateHeightAndWidthToSelectionsObject = (selections, options, choices) => {
  const heightAndWidthSelections = getHeightAndWidthByTag(choices);
  heightAndWidthSelections.forEach(choice => {
    selections[choice.optionId] = {
      choiceId: choice.id,
      choiceValue: choice.value
    };
  });

  return selections;
};

export default (
  afterConfiguratorLoaded,
  onMount,
  config,
  storeNumber,
  onSaveConfiguration,
  afterRulesExecuted,
  afterChoiceSelectionUpdate,
  mainImageUrl,
  sku,
  skuType,
  bypassSkuMap,
  isPipFlip,
  omsId
) => {
  const pipFlipExternalId = isPipFlip ? `?externalProductId=${omsId}` : '';
  // grab from route params so on load it gets the configuration selections
  const { id: configurationId } = config.configurationId || getParamsFromUrl() || '';

  const doNothing = () => new Promise(resolve => true); //eslint-disable-line
  const optionsApi = (configurationId) ? `/Catalog/ProductConfiguration/GetConfiguratorOptions${pipFlipExternalId}` :
    `/Catalog/ProductConfiguration/GetConfiguratorOptionsWithExternalProductId${pipFlipExternalId}`;
  const configuratorSettings = {
    templates,
    configurationId: configurationId,
    productLine: '',
    productLineId: '',
    // cartOmsId: config.cartOmsId,
    customValidators: {},
    // APIs
    priceApi: doNothing,
    alwaysShowOopsMessage: true,
    oopsMessage: 'Please select from an available option',
    executeRulesApi: executeAllRules,
    onRulesExecuted: afterRulesExecuted,
    onUpdateChoiceSelection: afterChoiceSelectionUpdate,
    createConfigurationApi: (params) => {
      const { productId, options, choices, selections } = params;
      if (bypassSkuMap && bypassSkuMap === 'True') {
        return createConfiguration(
          productId,
          appendHeightAndWidthToSelections(selections, options, choices),
          storeNumber,
          mainImageUrl,
          options,
          choices
        );
      }
      return createConfigurationWithSkuPricing(productId, options, choices, selections, storeNumber, mainImageUrl, sku, skuType);
    },
    editConfigurationApi: (options, choices, selections) => editConfiguration(options, choices, selections),
    // editConfigurationApi: (options, choices, selections) => editConfiguration(options, choices, selections),
    getOptionsApi: `${config.eleanorBaseUrl}${optionsApi}`,
    loadDictionaryDefinitionApi: `${config.eleanorBaseUrl}/DictionaryManagement/Definition/DictionaryDefinitionForTerm`,
    loadDictionaryTermsApi: `${config.eleanorBaseUrl}/DictionaryManagement/Definition/AllDictionaryEntries`,
    // End APIs
    siteId: config.siteId,
    productId: config.partnerProductId,
    productName: config.productName,
    storeSku: config.storeSku,
    externalProductId: config.externalProductId,
    logEnsightenEventsToConsole: config.logEnsightenEventsToConsole,
    brand: config.brand,
    userId: null,
    hiddenOptions: [982],
    mixPanelConfig: {
      timedEvent: 'PCP_TimeDevotion',
      isMixPanelActive: true,
      mixPanelCode: 'c2360a502ff32f3443acba1f20009e04',
    },
    preconfiguration: getPreconfiguration,
    persistenceKey: config.productId,
    eleanorBaseUrl: config.eleanorBaseUrl,
    zipcode: {},
    updateConfigurationButtonText: 'Update Rug',
    addConfigurationButtonText: 'Add to Cart',
    onLoadSuccess() {
      Promise.resolve(onMount())
        .then(() => Promise.all([afterConfiguratorLoaded()]))
    },
    onSaveSuccess(data) {
      LIFE_CYCLE_EVENT_BUS.trigger('configurator.configutationId', { configurationId: data.configurationGuid });
      onSaveConfiguration(data);
    },
    staticImages: {},
  };

  return configuratorSettings;
};
