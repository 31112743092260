import React from 'react';
import PropTypes from 'prop-types';
import getIndexableProp from '../../utils/getIndexableProp';
import { SwatchRoot } from './styles';

const Swatch = ({
  label,
  imageSrc,
  onClick,
  selected,
  href,
  indexable,
}) => {
  // const selectedClass = selected ? 'selected' : '';
  const relProp = getIndexableProp(indexable);
  return (
    <SwatchRoot selected={selected} onClick={onClick}>
      <span>
        <img alt={label} title={label} src={imageSrc} />
      </span>
      {/* These <a> tags with preselected choices are added on each choice for seo.
        This enables crawlers to know about the multiple configuration of the one product
        - nofollow is added to temporarily disable these links for crawler */}
      {/* eslint-disable jsx-a11y/anchor-has-content */}
      {(href && href.length > 0) && <a href={href} {...relProp} />}
    </SwatchRoot>
  );
};

Swatch.propTypes = {
  label: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  href: PropTypes.string,
  indexable: PropTypes.bool,
};

Swatch.defaultProps = {
  label: '',
  selected: false,
  href: '',
  indexable: false,
};

export default Swatch;
