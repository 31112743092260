import {
  UPDATE_CONFIG,
  UPDATE_LINEITEM,
  UPDATE_ISCOPY,
  UPDATE_CONFIGURATOR_MODE,
  SET_INITIAL_CONFIG,
} from '../actions/actionTypes';
import injectedConfiguratorSettings from '../config';

/* globals injectedConfiguratorSettings */
/* globals productMetas */
/* globals breadcrumbInfo */
const initialState = {
  ...injectedConfiguratorSettings,
  // ...productMetas,
  // breadcrumbs: breadcrumbInfo,
};

// never changes; we're just storing this in redux to help keep actions/connectors pure
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_CONFIG:
      const { config } = action;
      return {
        ...state,
        ...config,
      };
    case UPDATE_CONFIG: {
      const { configurationId } = action;

      return {
        ...state,
        configurationId,
      };
    }
    case UPDATE_CONFIGURATOR_MODE: {
      const { configuratorMode } = action;

      return {
        ...state,
        configuratorMode,
      };
    }
    case UPDATE_LINEITEM: {
      const { lineItemId } = action;

      return {
        ...state,
        lineItemId,
      };
    }
    case UPDATE_ISCOPY: {
      const { isCopy } = action;

      return {
        ...state,
        isCopy,
      };
    }
    default:
      return state;
  }
};
