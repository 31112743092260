import React from 'react';
import PropTypes from 'prop-types';
import flow from 'lodash/flow';
import find from 'lodash/find';
import camelCase from 'lodash/camelCase';
import { shouldHideOption } from '@gcc/configurator';
import Swatch from '../../../components/Swatch';
import ChoiceSectionHeaderV2 from '../../../components/ChoiceSectionHeader/v2';
import WarningMessages from '../../../components/WarningMessage';
import { OptionErrorMessages } from '../../../components/ErrorMessage';
import ConfiguratorTemplate from '../ConfiguratorTemplate';

class ColorDropdownTemplate extends React.Component {
  getDefinition = () => {
    const {
      attributes,
      option: {
        shortLabel,
      },
    } = this.props;

    return attributes[camelCase(shortLabel)];
  };

  getSelectedChoice() {
    const { choices } = this.props;
    return find(choices, 'isSelected');
  }

  getHrefForChoice = (choiceId) => {
    const { configLinks } = this.props;
    return configLinks[choiceId];
  };

  colorChange = ({ id: choiceId, shortLabel: choiceValue }) => {
    const selectedChoice = this.getSelectedChoice();
    if (!selectedChoice || (selectedChoice && choiceId !== selectedChoice.id)) {
      const { option, onChoiceSelected, onChoiceSelectedSuccess } = this.props;
      onChoiceSelected(option.id, choiceId, choiceValue)
        .then(() => onChoiceSelectedSuccess());
    }
  };

  renderDesktop = (selectedChoice) => {
    const {
      choices,
      option,
    } = this.props;

    return (
      <div className="product_sku_Overlay_ColorSwtHolder">
        <ul>
          {choices.map(choice => (
            <Swatch
              key={choice.id}
              label={choice.shortLabel}
              imageSrc={choice.mediaUrl}
              selected={choice === selectedChoice}
              onClick={() => this.colorChange(choice)}
              href={this.getHrefForChoice(choice.id)}
              indexable={option.isIndexable && option.isRequired}
            />
          ))}
        </ul>
      </div>
    );
  };

  render() {
    const selectedChoice = this.getSelectedChoice();
    const {
      option,
      options,
      productChoices,
    } = this.props;
    const shouldHide = shouldHideOption(option, options, productChoices);

    return !shouldHide && (
      <div className="product_sku_Overlay_ListBoxes" style={{ margin: '1em 0 1em' }}>
        <ChoiceSectionHeaderV2
          label={option && option.shortLabel}
          value={selectedChoice && selectedChoice.value}
          definitionHeader={option.shortLabel}
          definitionMarkdown={this.getDefinition()}
        />

        <OptionErrorMessages option={option} />
        {this.renderDesktop(selectedChoice)}
        <WarningMessages option={option} />
      </div>
    );
  }
}

ColorDropdownTemplate.propTypes = {
  choices: PropTypes.array.isRequired,
  option: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  productChoices: PropTypes.array.isRequired,
  onChoiceSelected: PropTypes.func.isRequired,
  onChoiceSelectedSuccess: PropTypes.func.isRequired,
  attributes: PropTypes.object.isRequired, // attributes is treated like a dictionary
  configLinks: PropTypes.array.isRequired,
};

export default flow(
  // connect to redux
  ConfiguratorTemplate,
)(ColorDropdownTemplate);
