import { useEffect } from 'react';
import merge from 'lodash/merge';
import map from 'lodash/map';

const useQuantity = (cart, skuMap, items, newData, onChangeGraphData, deliveryTime, qtyData, qty) => {
  useEffect(() => {
    map(cart, x => {
      const choiceQuantity = skuMap.subSkus.find(s => Number(s.sku) === x.itemId);
      const multipliedQuantity = choiceQuantity ? choiceQuantity.multiplier * qty : qty;

      return x.quantity = multipliedQuantity
    });

    const newQuantityData = {
      product: {
        configuration: {
          deliveryTime,
          cart: cart,
          items: items
        },
      }
    };
    const quantityData = merge(
      {},
      { ...newData },
      { ...newQuantityData },
    );

    return onChangeGraphData(quantityData);
  }, [qtyData]);
};

export default useQuantity;