import { put, call, select } from 'redux-saga/effects';
import { productDetailsAttributeSelector } from '../../selectors';

import {
  updateProductInfo,
  thdProductDetailsUpdated,
} from '../../actions/pricingActions';
import getThdProductDetailsOrNotItsCool from './_getThdProductDetailsOrNotItsCool';
import getLocalOrNationalPricing from './_getLocalOrNationalPricing';
import { getMappedSkus } from './_utils';

function* getThdProductDetailsAndPricing(skuMap, storeNumber) {
  const skus = getMappedSkus(skuMap);
  const attributes = yield select(productDetailsAttributeSelector);

  const allowDuplicateSubSkus = attributes?.allowDuplicateSubSkus;
  let thdDetails = {
    products: {
      product: [],
    },
  };
  if (allowDuplicateSubSkus) {
    const thdDetailsObj = yield call(getThdProductDetailsOrNotItsCool, skus, storeNumber);
    skuMap.subSkus.forEach((s) => {
      thdDetailsObj.products.product.forEach((p) => {
        if (Number(s.sku) === p.defaultSku) {
          thdDetails.products.product.push(p);
        }
      });
      return s;
    });
  } else {
    thdDetails = yield call(getThdProductDetailsOrNotItsCool, skus, storeNumber);
  }

  yield put(updateProductInfo({
    newSkus: skuMap || null,
    thdDetails,
  }));

  yield put(thdProductDetailsUpdated());

  const updatedPricing = yield call(getLocalOrNationalPricing);

  return updatedPricing;
}

export default getThdProductDetailsAndPricing;
