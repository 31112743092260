import React from 'react';
import PropTypes from 'prop-types';
import AlertWarning from '../alerts/AlertWarning';

const WarningMessages = props => (
  <React.Fragment>
    {!!props.option &&
      !!props.option.messages &&
      props.option.messages.filter(m => m.messageType.toLowerCase() === 'warning').map(({ message }) => (
        <AlertWarning key={message}>
          {message}
        </AlertWarning>
      ))}
  </React.Fragment>
);

WarningMessages.defaultProps = {
  option: null,
};

WarningMessages.propTypes = {
  option: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.shape({
      message: PropTypes.string,
      messageType: PropTypes.string,
    })),
  }),
};

export default WarningMessages;
