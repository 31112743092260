export const mapskus = (productInfo, config, skuMap) => {
  if (skuMap.isSpecialOrder) {
    let items = [];

    if (productInfo) {
      let keys = Object.keys(productInfo);
      let excludeItemKeys = ["DefaultProduct"];
      keys = keys.filter((item) => !excludeItemKeys.includes(item));
      
      if (keys.includes('primary') && Object.keys(productInfo['primary'].shipping).length !== 0) {
        const anchorQuantity = productInfo["primary"]?.storeInfo?.local?.inventory?.onHandQuantity;
        if (anchorQuantity > 0) {
          keys.forEach((key) => {
            items.push({
              itemId: productInfo[key].itemId,
              isAnchor: ["primary"].includes(key) ? true : false,
            });
          });
        } else {
          keys.forEach((key) => {
            items.push({
              itemId: productInfo[key].itemId,
              isAnchor: ["base"].includes(key) && productInfo[key].itemId !== config.partnerProductId ? true : false,
              isBase: ["base"].includes(key) ? true : false,
            });
          });
        }

        return items
      }

      keys.forEach((key) => {
        items.push({
          itemId: productInfo[key].itemId,
          isAnchor: ["base"].includes(key) && productInfo[key].itemId !== config.partnerProductId ? true : false,
          isBase: ["base"].includes(key) ? true : false,
        });
      });

      const hasAnchor = items.filter(i => i.isAnchor === true).length;
      if (skuMap.skuType === 'HomeDepot_OMSID' && items?.[0] && hasAnchor < 1) {
        items[0].isAnchor = true;
      }
    }

    return items
  }

  let items = [];
  let keys = Object.keys(productInfo);
  let excludeItemKeys = ["DefaultProduct"];
  keys = keys.filter((item) => !excludeItemKeys.includes(item));
  keys.forEach((key) => {
    items.push({
      itemId: productInfo[key].itemId,
      isAnchor: ["base","primary"].includes(key) ? true : false,
    });
  });
  
  return items;
};