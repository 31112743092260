/* eslint-disable no-restricted-globals */
import { call, put, select } from 'redux-saga/effects';
import { updateEarliestDelivery,
  getEarliestDeliveryOptionsFailure, getEarliestDeliveryOptionsStarted,
  setShowZipCodeAndCheck } from '../../actions/earliestDeliveryAction';
import { resetCart } from '../../actions/cartActions/_actionCreators';
import { getEarliestDelivery as getEarliestDeliveryApi } from '../../api/getEarliestDelivery';
import { productInfoSelector, earliestDeliveryInfoSelector } from '../../selectors';
import safeSaga from '../safeSaga';
import { parseDateByFormatString } from '../../utils/dateHelpers';
import { add } from 'date-fns';

const getItemIds = (productInfo) => {
  const itemIdArr = [];
  const keys = Object.keys(productInfo);
  keys.forEach((x) => {
    const { itemId } = productInfo[x];
    if (itemId > 0) {
      itemIdArr.push(itemId);
    }
    return itemId;
  });
  return itemIdArr.join(',');
};

const findAvailabilityStatus = (skus) => {
  if (skus?.every((x) => x.status === 'AVAILABLE')) {
    return 'AVAILABLE';
  }

  if (skus?.some((x) => x.status === 'BACK_ORDERED')) {
    return 'BACK_ORDERED';
  }

  // future status will go here

  return 'ERROR';
};

function* earliestDeliveryByZipCode() {
  try {
    const earliestDeliveryInfo = yield select(earliestDeliveryInfoSelector);

    if (earliestDeliveryInfo && earliestDeliveryInfo.zipCode
       && earliestDeliveryInfo.zipCode !== '' && earliestDeliveryInfo.zipCode.length === 5) {
      const productInfo = yield select(productInfoSelector);
      const itemIds = getItemIds(productInfo);
      if (itemIds != null) {
        yield put(getEarliestDeliveryOptionsStarted());
        yield put(setShowZipCodeAndCheck(false));
        yield put(resetCart());
        const data = yield call(getEarliestDeliveryApi, itemIds, earliestDeliveryInfo.zipCode);
        if (data != null && data?.deliveryAvailabilityResponse?.errorData == null) {
          const availability = data?.deliveryAvailabilityResponse?.deliveryAvailability?.availability;
          const isError = (data?.deliveryAvailabilityResponse?.deliveryAvailability?.zipCode === undefined
            || availability?.some((o) => o.status === 'UNAVAILABLE')
            || availability?.some((o) => o.csrStatusCode === '01'));
          let earliestDate;
          if (!isError) {
            earliestAvailabilityDate = data.deliveryAvailabilityResponse.deliveryAvailability?.earliestAvailabilityDate
            earliestDate =
            // eslint-disable-next-line max-len
            parseDateByFormatString(earliestAvailabilityDate, 'YYYY-MM-DD');


            earliestDate = earliestDate?.isValid() ? earliestDate.toDate() : add(new Date(), { days: 30 });
          }
          yield put(updateEarliestDelivery(
            earliestDate,
            isNaN(data?.deliveryAvailabilityResponse?.deliveryAvailability?.zipCode) ? ''
              : data?.deliveryAvailabilityResponse?.deliveryAvailability?.zipCode,
            findAvailabilityStatus(availability),
          ));

        } else {
          throw Error();
        }
      }
    }
  } catch (ex) {
    yield put(getEarliestDeliveryOptionsFailure());
  }
}

export default safeSaga(earliestDeliveryByZipCode);
