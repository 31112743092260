import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import camelCase from 'lodash/camelCase';
import ThdSelect from '../../../components/ThdSelect';
import TileSelect from '../../../components/TileSelect';
import WarningMessage from '../../../components/WarningMessage';
import ChoiceSectionHeaderV2 from '../../../components/ChoiceSectionHeader/v2';
import { OptionErrorMessages } from '../../../components/ErrorMessage';
import { Context as CollapseContext } from '../../../components/collapsibles';
import withIsMobile from '../../../components/withIsMobile';


class TileOrDropdownTemplate extends React.Component {
  getDefinition = () => this.props.attributes[camelCase(this.props.option.shortLabel)];

  getSelectedChoice() {
    const { choices } = this.props;
    return find(choices, 'isSelected');
  }

  getHrefForChoice = (choiceId) => {
    const { configLinks } = this.props;
    return configLinks[choiceId];
  };


  choiceChange = ({ id: choiceId, shortLabel: choiceValue }) => {
    const selectedChoice = this.getSelectedChoice();
    if (!selectedChoice || (selectedChoice && choiceId !== selectedChoice.id)) {
      const { option, onChoiceSelected } = this.props;
      onChoiceSelected(option.id, choiceId, choiceValue).then(() => this.props.onChoiceSelectedSuccess());
    }
  };
  autoSelectFirstChoice = (choice) => {
    const { choices, onChoiceSelected } = this.props;
    onChoiceSelected(choice.optionId, choice.id, choice.value)
      .then(() => this.props.onChoiceSelectedSuccess());
    return find(choices, 'isSelected');
  }

  renderMobile = (selectedChoice, filteredChoices) => {
    if (!selectedChoice) {
      const [choicetoSelect] = filteredChoices;
      // eslint-disable-next-line no-param-reassign
      selectedChoice = choicetoSelect;
    }
    return (
      <ThdSelect
        options={filteredChoices}
        value={selectedChoice}
        keyBy={choice => choice && choice.id}
        renderer={({ value }) => value && value.shortLabel}
        onChange={this.choiceChange}
        hrefBy={choice => this.getHrefForChoice(choice.id)}
        isWrapperOpen={window.prerender}
        nativeRenderer={({ value }) => value.value && value.shortLabel}
        indexable={this.props.option.isIndexable && this.props.option.isRequired}
      />
    );
  }
  ;

  renderDesktop = (selectedChoice, filteredChoices) => (
    <TileSelect
      options={filteredChoices}
      value={selectedChoice}
      keyBy={choice => choice && choice.id}
      renderer={({ value }) => value && value.shortLabel}
      onChange={this.choiceChange}
      hrefBy={choice => this.getHrefForChoice(choice.id)}
      indexable={this.props.option.isIndexable && this.props.option.isRequired}
    />
  );

  render() {
    // const selectedChoice = this.getSelectedChoice() || this.autoSelectFirstChoice(this.props.choices[0]);
    // const { option, isMobile } = this.props;
    const {
      option, isMobile, choices,
    } = this.props;
    let selectedChoice = null;
    let choiceInternalName = null;
    if (choices.length > 0) {
      selectedChoice = this.getSelectedChoice() || this.autoSelectFirstChoice(choices[0]);
      choiceInternalName = choices && choices.length > 0 && choices[0].internalName;
    }
    const renderSelect = isMobile ? this.renderMobile : this.renderDesktop;

    return (choices.length > 1 ||
          (choices.length === 1 &&
            ((choiceInternalName.length >= 3) && ((choiceInternalName.substring(0, 3)) !== 'No_'))
          )) &&

    (
      <div style={{ marginBottom: '20px' }}>
        <CollapseContext.Provider fullCount={choices.length}>
          <ChoiceSectionHeaderV2
            label={option && option.shortLabel}
            value={selectedChoice && selectedChoice.value}
            definitionHeader={option.shortLabel}
            definitionMarkdown={this.getDefinition()}
          />


          <OptionErrorMessages option={option} />
          <CollapseContext.Consumer>
            {() => renderSelect(selectedChoice, choices)}
          </CollapseContext.Consumer>
          <WarningMessage option={option} />
        </CollapseContext.Provider>
      </div>
    );
  }
}

TileOrDropdownTemplate.propTypes = {
  choices: PropTypes.array.isRequired,
  option: PropTypes.object.isRequired,
  onChoiceSelected: PropTypes.func.isRequired,
  onChoiceSelectedSuccess: PropTypes.func.isRequired,
  attributes: PropTypes.object.isRequired, // attributes is treated like a dictionary
  isMobile: PropTypes.bool.isRequired, // from `withIsMobile`
  configLinks: PropTypes.object.isRequired,
};

export default withIsMobile(TileOrDropdownTemplate);
